import { useAtom } from "jotai";
import { useEffect } from "react";
import { useGetUserClients } from "state/useGetUserClients";
import { useGetEmployeeData } from "state/useGetEmployeeData";
import { selectedClientAtom } from "store";

export function useInitSelectClient() {
  const { data: userClients } = useGetUserClients();
  const [selectedClient, setSelectedClient] = useAtom(selectedClientAtom);

  useGetEmployeeData({
    //save employee data in cache to use later
    clientInternalNumberGos: Number(selectedClient?.clientInternalNumberGos),
    countryCode: Number(selectedClient?.countryCode),
    enabled: !!selectedClient,
  });

  useEffect(() => {
    if (userClients && userClients.length > 0) {
      setSelectedClient(userClients[0]);
    }
  }, [userClients]);
}
