import { useAtom, useSetAtom } from "jotai";
import i18n from "../../i18n";

import Logo from "../../assets/GrECo_Logo_small.jpg";
import useNotificationHandler from "../../hooks/useNotificationHandler";

import { getHeaderConfig } from "./utils";

import useEmployee from "hooks/useEmployee";
import { useTranslation } from "react-i18next";
import { STORAGE_KEYS } from "../../library/shared";
import { SelectItem } from "../../library/types";
import { saveToLocalStorage } from "../../library/utils";
import { darkModeAtom, panelIsOpenState } from "../../store";
import { buildYupLocale } from "../../validation";
import GrecoHeader from "../GrecoHeader/GrecoHeader";

const AppHeader = () => {
  const { t } = useTranslation();
  const { notifications, handleClearAllNotifications } =
    useNotificationHandler();
  const { employeeData } = useEmployee();

  const [_, setDarkMode] = useAtom(darkModeAtom);
  const setPanelIsOpen = useSetAtom(panelIsOpenState);

  const headerConfig = getHeaderConfig(t("hbh.appName.label"));

  // const {} = useGetEmployeeData()

  return (
    <GrecoHeader
      // navigation={<Navigation />}
      headerConfig={headerConfig}
      panelStatus={setPanelIsOpen}
      user={employeeData}
      notifications={notifications}
      clearNotifications={handleClearAllNotifications}
      logo={Logo}
      darkMode={(isDarkMode: boolean) => {
        setDarkMode(isDarkMode);
      }}
      languageOptions={[
        {
          value: "en-GB",
          text: t("Language.EN_GB"),
        },
        {
          value: "de-AT",
          text: t("Language.DE_AT"),
        },
      ]}
      defaultLanguage={i18n.resolvedLanguage || i18n.language}
      onChangeLanguage={async (__event: any, languageOption?: SelectItem) => {
        console.log(languageOption, "languageOption");
        if (!languageOption) return;
        saveToLocalStorage(STORAGE_KEYS.appLang, languageOption?.value || "");

        i18n.changeLanguage(languageOption.value as string);
        buildYupLocale(null, t);
      }}
      headerStrings={{
        notificationsPanelStrings: {
          panelTitle: t("header.Notifications"),
          noNotificationsTitle: t("header.NoNotificationsTitle"),
          noNotificationsSubtitle: t("header.NoNotificationsSubtitle"),
        },
        settingsPanelStrings: {
          panelTitle: t("header.Settings"),
          darkModeToggleLabel: t("header.DarkMode"),
          selectLanguageLabel: t("header.SelectLanguage"),
        },
        userSettingsPanelStrings: {
          panelTitle: t("header.UserSettings"),
          myOfficeProfileLinkText: t("header.MyOfficeProfile"),
          myAccountLinkText: t("header.UserSettings"),
          signOutLinkText: t("header.SignOut"),
        },
      }}
    />
  );
};

export default AppHeader;
