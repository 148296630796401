import { darkStyledTheme, styledTheme } from "./mainTheme";
import { IStyledTheme } from "./types";

declare module "styled-components" {
  export interface DefaultTheme extends IStyledTheme {}
}

export const allThemes = {
  main: {
    light: styledTheme,
    dark: darkStyledTheme,
  },
};
