// --- Fabric UI
import {
  Button,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Persona,
  Tooltip,
} from "@fluentui/react-components";
import {
  EditRegular,
  SignOut20Regular,
  SignOutRegular,
} from "@fluentui/react-icons";
import { Flex } from "components/styled";
import { IEmployee } from "library/types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { PanelType as GrecoPanelType } from "../PanelType";
import { IUserSettingsStrings } from "./IUserSettingsStrings";
import { useCallback, useState } from "react";
import { EmployeeFormDialog } from "./EmployeeFormDialog/EmployeeFormDialog";
import { logout } from "service/auth";

interface IProps {
  userData: IEmployee | null;
}

export const UserSettings = ({ userData }: IProps) => {
  const { t } = useTranslation();

  const [employeeDetailsOpen, setEmployeeDetailsOpen] = useState(false);

  const handleDialogStateChange = useCallback(() => {
    setEmployeeDetailsOpen((prev) => !prev);
  }, []);

  // return (
  //   <>
  //     <Container>
  //       <Flex align="flex-start">
  //         <Persona
  //           avatar={{
  //             image: {
  //               src: userData?.backgroundPicturePath || "",
  //             },
  //             name: userData?.emailAddress,
  //           }}
  //           name={userData?.emailAddress}
  //           secondaryText={
  //             <Link id="logout" onClick={() => logout()}>
  //               <Flex align="center">
  //                 <SignOutRegular fontSize={18} />
  //                 {(strings && strings.signOutLinkText) || t("header.SignOut")}
  //               </Flex>
  //             </Link>
  //           }
  //           size="large"
  //         />
  //         <EditRegular
  //           onClick={() => {
  //             setEmployeeDetailsOpen(true);
  //           }}
  //           fontSize={16}
  //           style={{
  //             cursor: "pointer",
  //           }}
  //         />
  //       </Flex>
  //     </Container>
  //     {employeeDetailsOpen && userData && (
  //       <EmployeeFormDialog
  //         item={userData}
  //         open={employeeDetailsOpen}
  //         setOpen={handleDialogStateChange}
  //       />
  //     )}
  //   </>
  return (
    <>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <MenuButton
            style={{
              minWidth: "50px",
              maxWidth: "50px",
            }}
            appearance="transparent"
            icon={
              <Persona
                avatar={{
                  image: {
                    src: userData?.backgroundPicturePath || "",
                  },
                  name: userData?.emailAddress,
                }}
                secondaryText=""
                size="large"
              />
            }
            size="large"
          />
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            <MenuItem>
              <Button
                onClick={() => setEmployeeDetailsOpen(true)}
                appearance="transparent"
                icon={<EditRegular fontSize={18} />}
              >
                {t("hbh.editUser.label")}
              </Button>
            </MenuItem>

            <MenuItem>
              <Button
                onClick={() => logout()}
                appearance="transparent"
                icon={<SignOutRegular fontSize={18} />}
              >
                {t("header.SignOut")}
              </Button>
            </MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
      {employeeDetailsOpen && userData && (
        <EmployeeFormDialog
          item={userData}
          open={employeeDetailsOpen}
          setOpen={handleDialogStateChange}
        />
      )}
    </>
  );
};

const Container = styled.div`
  padding-top: 30px;
`;
