import { Avatar } from "@fluentui/react-components";
import { Flex } from "components/styled";
import { components } from "react-select";

export const CustomUserOption = (props) => {
  const data = props.data;

  return (
    <components.Option {...props}>
      <Flex>
        <Avatar
          image={{
            src: data?.backgroundPicturePath,
            alt: "Profile Image",
          }}
        />
        {data?.text}
      </Flex>
    </components.Option>
  );
};
