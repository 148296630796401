import { Button, Text } from "@fluentui/react-components";
import { BotRegular, GiftRegular, PersonRegular } from "@fluentui/react-icons";
import { SelectClient } from "components/SelectClient/SelectClient";
import { useAppTheme } from "hooks/useAppTheme";
import { devices, ROUTES, sizes } from "library/constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export function AppNavigation() {
  const theme = useAppTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToPage = (page) => {
    navigate(page);
  };

  return (
    <>
      <Container>
        <NavItem onClick={() => goToPage(ROUTES.HOME)}>
          <StyledButton
            size="small"
            $bgColor={theme.palette.themePrimary}
            shape="circular"
            icon={<PersonRegular />}
            name="Health"
          />
          <Text>{t("hbh.health.label")}</Text>
        </NavItem>
        <NavItem onClick={() => goToPage(ROUTES.BENEFITS)}>
          <StyledButton
            size="small"
            $bgColor={theme.palette.green}
            shape="circular"
            $active={true}
            icon={<GiftRegular />}
          />
          <Text>{t("hbh.benefits.label")}</Text>
        </NavItem>
        <SelectClient onlyDesktop />
        <NavItem onClick={() => goToPage(ROUTES.HOME)} $autoMl>
          <StyledButton
            size="small"
            $bgColor={theme.palette.orangeLighter}
            shape="circular"
            $active={false}
            icon={<BotRegular />}
          />
          <Text>{t("hbh.askQuestion.label")}</Text>
        </NavItem>
      </Container>
    </>
  );
}

const Container = styled.div`
  background: ${({ theme }) => theme.palette.white};
  background-color: ${(props) => props.theme.palette.white};
  border-top: 1px solid ${(props) => props.theme.palette.neutralLight};
  box-shadow: 0px -4px 6px -2px ${(props) => props.theme.palette.neutralLight};

  display: flex;
  box-sizing: border-box;
  gap: 30px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  justify-content: space-around;
  padding: 5px;
  height: ${sizes.appNavHeightMobile}px;

  @media only screen and ${devices.md} {
    height: ${sizes.appNavHeightDesktop}px;
    position: relative;
    box-shadow: 0px 4px 6px -2px ${(props) => props.theme.palette.neutralLight};

    justify-content: flex-start;
    padding: 0 40px;
  }
`;

const StyledButton = styled(Button)<{ $active?: boolean; $bgColor: string }>`
  background: ${({ theme, $bgColor }) => $bgColor};
  border: ${({ theme, $active }) =>
    `1px solid ${$active ? theme.palette.themePrimary : "transparent"}`};
  color: ${({ theme }) => theme.palette.white};
  display: flex;
  width: 100%;
  // height: 50px;
  line-height: 50px;
`;

const NavItem = styled.div<{ $autoMl?: boolean }>`
  display: flex;
  gap: 5px;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  // :hover {
  //   background: transparent;
  // }
  // button:hover {
  //   border: ${({ theme }) => `1px solid ${theme.palette.themePrimary}`};
  // }

  @media only screen and ${devices.md} {
    margin-left: ${({ $autoMl }) => ($autoMl ? "auto" : "0")};
    flex-direction: row;
  }
`;
