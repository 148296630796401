import React from "react";

import Select, { components } from "react-select";

import { tokens } from "@fluentui/react-components";
import { useAppTheme } from "hooks/useAppTheme";
import { SelectItem } from "library/types";
import { useTranslation } from "react-i18next";
import { CustomUserOption } from "./CustomUserOption";

const GroupHeading = (props) => {
  const theme = useAppTheme();
  const groupStyles = {
    color: "white",
    background: `${theme.palette.themePrimary}`,
    borderBottom: `1px solid ${theme.palette.white}`,
    padding: "5px 0px",
    display: "flex",
  };
  return (
    <div style={groupStyles}>
      <components.GroupHeading {...props} />
    </div>
  );
};

type Props = {
  employeeOption: SelectItem[];
  dependentOptions: SelectItem[];
  value: SelectItem | null;
  setFieldValue: (value) => void;
};
const PackageUserSelect = ({
  employeeOption,
  dependentOptions,
  value,
  setFieldValue,
}: Props) => {
  const theme = useAppTheme();

  const { t } = useTranslation();
  const onChange = (option) => {
    setFieldValue(option);
  };

  return (
    <Select
      inputValue={""}
      options={[
        {
          label: t("hbh.employee.label"),
          options: employeeOption,
        },
        {
          label: t("hbh.dependents.label"),
          options: dependentOptions,
        },
      ]}
      value={[...employeeOption, ...dependentOptions]?.filter(function (
        option
      ) {
        return option.value === value?.value;
      })}
      components={{ GroupHeading, Option: CustomUserOption }}
      onChange={onChange}
      styles={{
        groupHeading: (base) => ({
          ...base,
          flex: "1 1",
          color: theme.palette.white,
          margin: 0,
        }),
        container: (baseStyles) => ({
          ...baseStyles,
          position: "relative",
          width: "100%",
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          background: theme.palette.neutralLighter,

          boxShadow: "none",
          ...(state.isDisabled && {
            borderBottom: `1px solid ${tokens.colorNeutralStrokeAccessible} !important`,
          }),
        }),
        menu: (p) => ({
          ...p,
          zIndex: 15,
          background: theme.palette.neutralLighter,
        }),
        option: (p, state) => ({
          ...p,
          background: state.isFocused
            ? theme.palette.neutralLighter
            : theme.palette.white,
          borderLeft: state.isSelected
            ? `4px solid ${theme.palette.themePrimary}`
            : `4px solid ${theme.palette.white}`,
          "&:hover": {
            background: theme.palette.neutralLighter,
          },
          color: theme.palette.black,
          fontSize: "14px",
          fontWeight: 400,
        }),
        input: (p) => ({
          ...p,
          margin: "4px",
          color: (function () {
            return theme.palette.black;
          })(),
        }),

        singleValue: (p, state) => ({
          ...p,
          fontSize: "14px",
          marginLeft: "5px",
          fontWeight: 400,
          color: theme.palette.black,
        }),
        dropdownIndicator: (p, state) => ({
          ...p,
          ...(state.isDisabled && { visibility: "hidden" }),
        }),

        indicatorSeparator: (p, state) => ({
          ...p,
          visibility: "hidden",
        }),
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999,
          overflow: "visible",
        }),
      }}
    />
  );
};

export default React.memo(PackageUserSelect);
