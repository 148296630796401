import useEmployee from "hooks/useEmployee";
import { devices } from "library/constants";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import HbhLogo from "../../../../../assets/H&B_logo.png";

export function BenefitsPageHeader() {
  const { t } = useTranslation();
  const { employeeData } = useEmployee();
  return (
    <Container>
      <Title>
        {t("hbh.greeting.label", {
          name: employeeData?.firstName || "",
        })}
      </Title>
      <Title>{t("hbh.welcome.label")}</Title>
      <img src={HbhLogo} alt="H&B Logo" />
      <Subtitle>{t("hbh.subtitle.label")}</Subtitle>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
  @media only screen and ${devices.md} {
    gap: 15px;
  }
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  @media only screen and ${devices.md} {
    font-size: 24px;
  }
`;

const Subtitle = styled.h2`
  font-weight: 400;
  font-size: 14px;
  margin: 0;

  @media only screen and ${devices.md} {
    font-size: 18px;
  }
`;
