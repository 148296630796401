import axios from "axios";
import { ROUTES } from "library/constants";
import { getCookieValue, urlFormat } from "library/utils";
import { cacheLocation, getToken } from "service/auth";

let authHeaders = {};

const url = urlFormat(process.env.REACT_APP_BASE_URL || "");

const axiosInstance = axios.create({
  baseURL: url,
});

async function execute(func: any, url: string, config: {}) {
  try {
    const response = await func(url, config);
    return {
      status: response.status,
      statusText: response.statusText,
      data: response.data,
    };
  } catch (error: any) {
    if (error.response.status === 403) {
      window.location.href = ROUTES.UNAUTHORIZED_PAGE;
    }
    // if (error.response) {
    //   const err = {
    //     status: error?.response?.status,
    //     statusText: error?.response?.statusText,
    //     error:
    //       error?.response?.data?.error || error?.response?.data?.data?.error,
    //     message:
    //       error?.response?.data?.message ||
    //       error?.response?.data?.data?.message ||
    //       "",
    //   };

    //   throw err;
    // }

    throw error;
  }
}

const api = {
  setAuthHeaders: (headers: {}) => {
    authHeaders = {
      ...authHeaders,
      ...headers,
    };
  },
  get: async (url: string, config = {}) =>
    execute(axiosInstance.get, url, config),
  post: async (url: string, config = {}) =>
    execute(axiosInstance.post, url, config),
  put: async (url: string, config = {}) =>
    execute(axiosInstance.put, url, config),
  patch: async (url: string, config = {}) =>
    execute(axiosInstance.patch, url, config),
  delete: async (url: string, config = {}) =>
    execute(axiosInstance.delete, url, config),
};

export default api;

axiosInstance.interceptors.request.use(async (config) => {
  const authRes = await getToken();
  let token;
  if (authRes) {
    token = authRes.accessToken || authRes.idToken.rawIdToken;
  }
  if (!token) {
    token = cacheLocation.getItem("msal.idtoken");
    console.log("Token from local storage", token);
  }
  if (!token) {
    token = getCookieValue("msal.idtoken");
    console.log("Token from cookie", token);
  }
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});
