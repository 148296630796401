export const STORAGE_KEYS = {
  darkMode: "dark-mode",
  appLang: "app-lang",
  isListBenefitView: "is-list-benefit-view",
  selectedTheme: "selected-theme",
};

export const QUERY_KEYS = {
  taxonomies: "taxonomies",
  userClients: "userClients",
  employeeData: "employeeData",
  dependentData: "dependentData",
  companyBenefitPackageViews: "companyBenefitPackageViews",
  employeeEnrollments: "employeeEnrollments",
  enrollmentCancellationPossibilityDate:
    "enrollmentCancellationPossibilityDate",
};

export const MUTATION_KEYS = {
  updateEmployee: "updateEmployee",
  createDependent: "createDependent",
  updateDependent: "updateDependent",
  deleteDependent: "deleteDependent",
  enrollPackage: "enrollPackage",
  cancelEnrollment: "cancelEnrollment",
};

export const ROUTE_PARAMS = {};
