import { format, formatDate, isAfter, isBefore, isToday } from "date-fns";
import i18next from "i18next";
import { toast } from "react-toastify";
import { IBackendError, IFormattedFile } from "../types";

export const debounce = (callback, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

export const EMAIL_ID_SEPARATOR = "###";

export const formatDateString = (date?: string) => {
  return date !== null && date !== "" && date
    ? format(new Date(date), "dd.MM.yyyy")
    : null;
};
export const formatFullDate = (date: string) => {
  return date !== null && date !== ""
    ? format(new Date(date), "dd.MM.yyyy hh:mm:ss")
    : "";
};

export const formatDateWithTime = (date: Date) =>
  format(date, "yyyy_MM_dd_kk_mm_ss");

export const firstLetterUpper = (string: string) => {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatNumber = (
  n: number,
  minPrecision = 2,
  maxPrecision = 2,
  returnValue = ""
) => {
  if (!n) return "";
  return n.toLocaleString("de-DE", {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  });
};

export const getTaxonomyValue = (
  options: {
    value: string | number;
    label: string;
  }[],
  fieldValue: string,
  t: any
) => t(options?.find((option) => option.value === fieldValue)?.label);

export const urlFormat = (url?: string) => {
  if (!url) return "";

  let result = url;
  if (url.charAt(url.length - 1) === "/") {
    result = url.slice(0, -1);
  }
  return result;
};

export const openLinkInNewTab = (path: string) => {
  return window.open(path);
};

export const genUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const formBase64String = (file: File) => {
  const reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing file"));
    };

    reader.onload = () => {
      resolve(
        reader.result
          ? typeof reader.result === "string"
            ? reader.result.substring(reader.result.indexOf(",") + 1)
            : ""
          : ""
      );
    };
    reader.readAsDataURL(file);
  });
};

export const formatFile = async (file) => {
  if (!file) return null;
  const data = await {
    name: file.name,
    base64String: await formBase64String(file),
    type: file.type,
  };
  return data as IFormattedFile;
};

export function getCookieValue(a: string) {
  var b = document.cookie.match("(^|[^;]+)\\s*" + a + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

export const deleteAllCookies = () => {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};

export function deepEqual(object1 = {}, object2 = {}) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}
export function isObject(object) {
  return object != null && typeof object === "object";
}

export const handleAxiosError = (err?: any) => {
  console.log(err, "error");
  const response = err.response;
  if (response) {
    const responseData = err.response.data;
    if (responseData) {
      const env = process.env.REACT_APP_ENVIRONMENT as string;
      const developmentEnvironments = ["L", "D", "T"];
      const isDevOrTestEnv = developmentEnvironments.includes(env);
      if (Array.isArray(responseData)) {
        responseData.forEach((e: IBackendError) => {
          const translatedMessageVariables = e.messageVariables.map(
            (item: string) => {
              return i18next.t(item);
            }
          );
          let message = e.messageCode;
          if (isDevOrTestEnv) {
            message = e?.exceptionMessage || e?.formatedMessage;

            toast.error(i18next.t(message));
          } else {
            // toast.error(
            //   i18next.t(message, translatedMessageVariables.join(","))
            // );
            toast.error(
              i18next.t(message, translatedMessageVariables.join(","))
            );
          }
        });
      }
      //
      else {
        let message = responseData.messageCode;
        if (message) {
          if (isDevOrTestEnv) {
            message =
              responseData?.exceptionMessage || responseData?.formatedMessage;
          }

          const translatedMessageVariables = responseData.messageVariables.map(
            (item: string) => {
              return i18next.t(item);
            }
          );

          const toastMessage = translatedMessageVariables.length
            ? translatedMessageVariables.join("\r\n")
            : message;

          // toast.error(i18next.t(message, translatedMessageVariables));

          toast.error(i18next.t(toastMessage));
        } else {
          if (responseData.message) {
            toast.error(responseData.message);
          } else {
            toast.error(responseData);
          }
        }
      }
    } else {
      toast.error(err?.toString());
    }
  } else {
    toast.error(err?.message?.toString() || err?.statusText?.toString());
  }
};

export const formatSelectFieldForPayload = ({ values, field }) => {
  return values[field]?.value ? values[field].value : values[field];
};
export const formatDateFieldForPayload = (value) => {
  try {
    if (!value) return;
    return formatDate(value, "yyyy-MM-dd");
  } catch (error) {
    console.log(value, "value");
    console.log(error, "value");
  }
};

export const downloadFile = (data: any, fileName?: string) => {
  const link = document.createElement("a");
  link.href = data?.url;
  if (data?.url) {
    // const fileName = data?.url.split("/").pop().split("?")[0];
    // openLinkInNewTab(data?.url);
    link.setAttribute("download", fileName || "");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};

export const getBase64 = async (file: Blob): Promise<string | undefined> => {
  var reader = new FileReader();
  reader.readAsDataURL(file as Blob);

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      const dataUrl = reader.result as any;
      const base64String = dataUrl?.split(",")[1];
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const loadFromLocalStorage = (key: string, defaultValue: any) => {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : defaultValue;
};

// Function to save to localStorage
export const saveToLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const isInteger = (str) => {
  return /^-?\d+$/.test(str);
};

export function isBeforeOrOnToday(date) {
  return isBefore(date, new Date()) || isToday(date);
}

export function isAfterOrOnToday(date) {
  return isAfter(date, new Date()) || isToday(date);
}
