import { Text } from "@fluentui/react-components";
import { SquareFilled } from "@fluentui/react-icons";
import { Flex } from "components/styled";
import { useAtom, useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import { darkModeAtom, selectedThemeAtom } from "store";
import styled from "styled-components";
import { allThemes } from "theme";
import { darkStyledTheme, styledTheme } from "theme/mainTheme";
import { EThemeMode, EThemeName, IStyledTheme } from "theme/types";

export function ThemeSelector() {
  const { t } = useTranslation();
  const darkMode = useAtomValue(darkModeAtom);
  const mainTheme = darkMode ? darkStyledTheme : styledTheme;

  const [selectedThemeName, setSelectedTheme] = useAtom(selectedThemeAtom);

  const theme = allThemes[selectedThemeName][
    darkMode ? EThemeMode.dark : EThemeMode.light
  ] as IStyledTheme;

  return (
    <Container>
      <Text>{t("hbh.themeSelector.label")}</Text>

      <Flex gap={"10px"}>
        <Wrap
          selectedColor={
            selectedThemeName === EThemeName.main
              ? theme.palette.themePrimary
              : ""
          }
        >
          <SquareFilled
            onClick={() => {
              setSelectedTheme(EThemeName.main);
            }}
            style={{
              color: mainTheme.palette.themePrimary,
              width: "30px",
              height: "30px",
            }}
          />
        </Wrap>
      </Flex>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Wrap = styled.div<{ selectedColor?: string }>`
  border: ${({ theme, selectedColor }) =>
    selectedColor
      ? `2px solid ${selectedColor}`
      : `1px solid ${theme.palette.neutralLight}`};
  cursor: pointer;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.palette.white};
  border-radius: 4px;
`;
