import { useAtomValue } from "jotai";
import { useGetEmployeeData } from "state/useGetEmployeeData";
import { selectedClientAtom } from "store";

export function useEmployee() {
  const selectedClient = useAtomValue(selectedClientAtom);

  const { data: employeeData, isLoading: employeeDataLoading } =
    useGetEmployeeData({
      clientInternalNumberGos: Number(selectedClient?.clientInternalNumberGos),
      countryCode: Number(selectedClient?.countryCode),
      enabled: !!selectedClient,
    });

  return {
    employeeData,
    employeeDataLoading,
  };
}

export default useEmployee;
