import { Flex, StyledTab, StyledTabList } from "components/styled";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { GiftOpenRegular, GiftRegular } from "@fluentui/react-icons";
import useEmployee from "hooks/useEmployee";
import { useGetMsalUserEmail } from "hooks/useGetMsalUserEmail";
import { useAtom } from "jotai";
import { devices } from "library/constants";
import { EBenefitContentTab, SelectItem } from "library/types";
import { useEffect, useMemo } from "react";
import {
  benefitContentTabAtom,
  selectedPackageUserAtom,
} from "store/UIBenefitsPage";
import PackageUserSelect from "./PackageUserSelect/PackageUserSelect";

export function ContentSelector() {
  const { t } = useTranslation();

  const userUniqueEmail = useGetMsalUserEmail();

  const { employeeData } = useEmployee();
  const [selectedContentTab, setSelectedContentTab] = useAtom(
    benefitContentTabAtom
  );
  const [selectedPackageUser, setSelectedPackageUser] = useAtom(
    selectedPackageUserAtom
  );

  const tabs = [
    {
      value: EBenefitContentTab.enrolled,
      name: t("hbh.enrolled.label"),
      icon: <GiftOpenRegular />,
    },
    {
      value: EBenefitContentTab.eligible,
      name: t("hbh.eligible.label"),
      icon: <GiftRegular />,
    },
  ];

  const packageUserOptions = useMemo(() => {
    const options = [] as SelectItem[];
    const employeeOptions = [] as SelectItem[];
    const dependentsOptions = [] as SelectItem[];
    if (!employeeData)
      return {
        options,
        employeeOptions,
        dependentsOptions,
      };

    employeeOptions.push({
      ...employeeData,
      value: userUniqueEmail || "",
      text: `${employeeData?.firstName} ${employeeData?.lastName}`,
      label: `${employeeData?.firstName} ${employeeData?.lastName}`,
    });
    employeeData?.dependents?.forEach((dependent) => {
      dependentsOptions.push({
        ...dependent,
        value: dependent.employeeDependentTag || "",
        text: `${dependent?.firstName} ${dependent?.lastName}`,
        label: `${dependent?.firstName} ${dependent?.lastName}`,
      });
    });
    return {
      options: [...employeeOptions, ...dependentsOptions],
      employeeOptions,
      dependentsOptions,
    };
  }, [employeeData?.firstName]);

  useEffect(() => {
    setSelectedPackageUser({
      value: userUniqueEmail || "",
      text: `${employeeData?.firstName} ${employeeData?.lastName}`,
    });
  }, [employeeData]);

  if (!employeeData) return;

  return (
    <Container>
      <StyledTabList
        selectedValue={selectedContentTab}
        onTabSelect={(e, data) => {
          setSelectedContentTab(data.value as EBenefitContentTab);
        }}
        style={{
          overflowX: "auto",
        }}
      >
        {tabs.map((tab) => {
          return (
            <StyledTab key={tab.value} icon={tab.icon} value={tab.value}>
              {tab.name}
            </StyledTab>
          );
        })}
      </StyledTabList>
      <Flex width={"200px"}>
        {/* <SelectField
          appearance="filled-darker"
          error={""}
          setFieldValue={(name, value) => {
            console.log(name, value);
            setSelectedPackageUser(value);
          }}
          value={selectedPackageUser as any}
          noLabel
          noErrorSection
          options={packageUserOptions.options}
          name={"genderCodeId"}
        /> */}
        <PackageUserSelect
          employeeOption={packageUserOptions.employeeOptions}
          dependentOptions={packageUserOptions.dependentsOptions}
          value={selectedPackageUser}
          setFieldValue={(value) => {
            setSelectedPackageUser(value);
          }}
        />
      </Flex>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  //   flex-direction: column;

  justify-content: flex-start;

  @media only screen and ${devices.md} {
    flex-direction: row;

    justify-content: space-between;
  }
`;
