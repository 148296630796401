import {
  Avatar,
  MenuItem,
  MenuList,
  MenuPopover,
  Text,
  Tooltip,
} from "@fluentui/react-components";
import { devices } from "library/constants";
import { useGetUserClients } from "state/useGetUserClients";
import styled from "styled-components";

import {
  Menu,
  MenuButtonProps,
  MenuTrigger,
  SplitButton,
} from "@fluentui/react-components";
import { Flex } from "components/styled";
import { useAtom } from "jotai";
import { selectedClientAtom } from "store";

type Props = {
  onlyMobile?: boolean;
  onlyDesktop?: boolean;
};

export function SelectClient({ onlyMobile, onlyDesktop }: Props) {
  const { data: clientOptions = [] } = useGetUserClients();

  const [selectedClient, setSelectedClient] = useAtom(selectedClientAtom);

  const filteredClientOptions =
    clientOptions?.filter(
      (item) =>
        item.clientInternalNumberGos !== selectedClient?.clientInternalNumberGos
    ) || [];

  if (!clientOptions.length) return null;

  const withMultiClients = Boolean(clientOptions?.length > 1);

  return (
    <Container
      $withMultiClients={withMultiClients}
      $onlyMobile={onlyMobile}
      $onlyDesktop={onlyDesktop}
    >
      <Menu positioning="below-end">
        <MenuTrigger disableButtonEnhancement>
          {(triggerProps: MenuButtonProps) => (
            <SplitButton
              disabled={!withMultiClients}
              className="clientsSelectBtn"
              style={{
                paddingTop: "0 !important",
                paddingBottom: "0 !important",
              }}
              menuButton={{
                ...triggerProps,
              }}
              appearance="transparent"
            >
              {/* <Flex
              style={{
                color: theme.palette.themePrimary,
              }}
              >
                <Link
            href={selectedClient?.backgroundPicturePath || ""}
            target="_blank"
          >
            <AvatarWithPreview
              shape="square"
              name={selectedClient?.clientName}
              imgSrc={selectedClient?.backgroundPicturePath || ""}
            />
          </Link> 
              </Flex> */}
              <Tooltip
                content={selectedClient?.clientName || ""}
                relationship="description"
              >
                <Avatar
                  name={selectedClient?.clientName}
                  className="mobileViewClient"
                />
              </Tooltip>
              <Text className="desktopViewClient">
                {selectedClient?.clientName}
              </Text>
            </SplitButton>
          )}
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            {filteredClientOptions?.map((item) => (
              <MenuItem
                onClick={() => {
                  setSelectedClient(item);
                }}
              >
                <Flex>{item.clientName}</Flex>
              </MenuItem>
            ))}
          </MenuList>
        </MenuPopover>
      </Menu>
    </Container>
  );
}

const Container = styled.div<{
  $withMultiClients?: boolean;
  $onlyMobile?: boolean;
  $onlyDesktop?: boolean;
}>`
  display: ${({ $onlyDesktop }) => ($onlyDesktop ? "none" : "flex")};

  .mobileViewClient {
    display: flex;
  }
  .desktopViewClient {
    display: none;
  }
  .clientsSelectBtn {
    min-width: 50px;
    button {
      max-width: 50px;
      min-width: 50px;
    }
  }
  .fui-MenuButton__menuIcon {
    color: ${({ theme }) => theme.palette.white};
  }

  .fui-SplitButton__menuButton {
    display: ${({ $withMultiClients }) =>
      $withMultiClients ? "flex" : "none"};
  }
  .fui-SplitButton__primaryActionButton {
    border-right-color: ${({ $withMultiClients, theme }) =>
      $withMultiClients ? "" : "transparent"};
  }

  button:disabled,
  button:focus,
  button:active {
    color: ${({ theme }) => theme.palette.themePrimary} !important;
    cursor: default !important;
  }

  @media only screen and ${devices.md} {
    .mobileViewClient {
      display: none;
    }
    .desktopViewClient {
      display: flex;
    }

    .clientsSelectBtn {
      button {
        max-width: fit-content;
      }
    }

    .fui-MenuButton__menuIcon {
      color: inherit;
      font-size: 14px;
      font-weight: 600;
    }

    display: ${({ $onlyMobile }) => ($onlyMobile ? "none" : "flex")};
    height: 100%;
    color: ${({ theme }) => theme.palette.themePrimary};
  }
`;
