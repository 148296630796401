import { PersonRegular, SaveRegular } from "@fluentui/react-icons";
import { AppLogoSpinner } from "components/AppLogoSpinner/AppLogoSpinner";
import { EmployeeFormFields } from "components/GrecoHeader/GrecoPanel/UserSettings/EmployeeFormDialog/UpdateEmployeeFields/EmployeeFormFields/EmployeeFormFields";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { Flex, PageContent } from "components/styled";
import { Form, Formik } from "formik";
import useEmployee from "hooks/useEmployee";
import { useGetMsalUserEmail } from "hooks/useGetMsalUserEmail";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { useAtomValue } from "jotai";
import { ETaxonomy, IEmployee } from "library/types";
import {
  formatDateFieldForPayload,
  formatSelectFieldForPayload,
} from "library/utils";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useUpdateEmployee } from "state/useUpdateEmployee";
import { selectedClientAtom } from "store";
import * as yup from "yup";
import { ProfileHeader } from "./ProfileHeader/ProfileHeader";
import styled from "styled-components";
import { Card } from "@fluentui/react-components";
import { devices } from "library/constants";

export function ProfilePage() {
  const { employeeData } = useEmployee();

  const { t } = useTranslation();

  const loginMail = useGetMsalUserEmail();

  const selectedClient = useAtomValue(selectedClientAtom);
  const genderOptions = useTaxonomyOptions(ETaxonomy.Gender);
  const countryOptions = useTaxonomyOptions(ETaxonomy.Country);
  const { mutate: onUpdateEmployee, isLoading: updateEmployeeInProgress } =
    useUpdateEmployee();

  const handleSubmit = useCallback(
    (values) => {
      onUpdateEmployee(
        {
          countryCode: Number(selectedClient?.countryCode),
          clientInternalNumberGOS: Number(
            selectedClient?.clientInternalNumberGos
          ),
          payload: {
            ...values,
            genderCodeId: formatSelectFieldForPayload({
              values,
              field: "genderCodeId",
            }),
            citizenshipCodeId: formatSelectFieldForPayload({
              values,
              field: "citizenshipCodeId",
            }),

            dateOfBirth: formatDateFieldForPayload(values.dateOfBirth),
            ...(values.employeeCoverPhoto?.base64String && {
              backgroundPictureContent: values.employeeCoverPhoto?.base64String,
              backgroundPictureName: values.employeeCoverPhoto?.name,
              backgroundPicturePath: values.employeeCoverPhoto?.path,
              backgroundPictureMimeType: values.employeeCoverPhoto?.type,
            }),

            backgroundPicturePath: values.employeeCoverPhoto?.path,
            dependents: values.dependents.map((dependent) => {
              return {
                ...dependent,
                rowVersion: dependent.rowVersion || employeeData?.rowVersion,
              };
            }),
          },
        },
        {
          onSuccess() {
            toast.success(t("hbh.employeeUpdatedSuccessfully.label"));
          },
        }
      );
    },
    [selectedClient, employeeData]
  );

  const initialValues = useMemo(() => {
    return {
      //main
      ...employeeData,
      dependents:
        (employeeData?.dependents as IEmployee["dependents"])?.map(
          (dependent) => {
            return {
              ...dependent,
              dependentCoverPhoto: dependent?.backgroundPicturePath
                ? {
                    name: dependent?.backgroundPictureName,
                    path: dependent?.backgroundPicturePath,
                    base64String: dependent?.backgroundPictureContent,
                    type: dependent?.backgroundPictureMimeType,
                  }
                : null,
            };
          }
        ) || [],

      //basic
      genderCodeId: genderOptions.find(
        (genderCode) => genderCode.value === employeeData?.genderCodeId
      ),
      citizenshipCodeId: countryOptions.find(
        (country) => country.value === employeeData?.citizenshipCodeId
      ),

      employeeCoverPhoto: employeeData?.backgroundPicturePath
        ? {
            name: employeeData?.backgroundPictureName,
            path: employeeData?.backgroundPicturePath,
            base64String: employeeData?.backgroundPictureContent,
            type: employeeData?.backgroundPictureMimeType,
          }
        : null,
    };
  }, [employeeData, genderOptions, countryOptions]);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      emailAddress: yup.string().required(),
      mobilePhoneNumber: yup.string().required(),
      dateOfBirth: yup.string().required(),

      dependents: yup.array().of(
        yup.object().shape({
          dependentType: yup.mixed().required(),
          firstName: yup.string().required(),
          lastName: yup.string().required(),
        })
      ),
    });
  }, []);

  if (!employeeData) {
    return <AppLogoSpinner />;
  }
  return (
    <>
      <PageContent>
        <ProfileHeader />
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            handleSubmit(values);
            actions.setSubmitting(false);
          }}
        >
          {() => {
            return (
              <Form>
                <Flex direction="column">
                  {/* <Flex width={"100%"} justify="flex-start">
                    <PersonRegular />
                    <span>
                      {`${employeeData.firstName} ${employeeData.lastName}`}{" "}
                    </span>
                    <span>({loginMail})</span>
                  </Flex> */}
                  <FormFieldsWrap>
                    <EmployeeFormFields employeeDetails={employeeData} />
                  </FormFieldsWrap>
                  <Flex>
                    <PrimaryButton
                      icon={<SaveRegular />}
                      style={{
                        paddingLeft: "25px",
                        paddingRight: "25px",
                      }}
                    >
                      {t("greco.save")}
                    </PrimaryButton>
                  </Flex>
                </Flex>
              </Form>
            );
          }}
        </Formik>
      </PageContent>
    </>
  );
}

const FormFieldsWrap = styled(Card)`
  display: flex;
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  padding: 5px;

  @media only screen and ${devices.md} {
    padding: 20px;
  }
`;
