import { BenefitCard } from "components/BenefitCard/BenefitCard";
import { CardListWrap } from "components/styled";
import { useAtomValue } from "jotai";
import { ICardBenefitPackage } from "library/types";
import { selectedPackageUserAtom } from "store/UIBenefitsPage";

type Props = {
  data: ICardBenefitPackage[];
};
export function EnrolledPackages({ data }: Props) {
  const selectedPackageUser = useAtomValue(selectedPackageUserAtom);

  return (
    <CardListWrap>
      {data
        ?.filter((item) => {
          if (item.isDependent) {
            return item.employeeDependentTag === selectedPackageUser?.value;
          }
          return item.employeeLoginEmailAddress === selectedPackageUser?.value;
        })
        .map((benefit) => {
          return (
            <BenefitCard
              isDependent={benefit.isDependent}
              selected
              key={benefit.benefitPackageTag}
              benefitPackage={benefit}
            />
          );
        })}
    </CardListWrap>
  );
}
