import * as yup from "yup";
import i18n from "./i18n";
import { TFunction } from "i18next";

export function buildYupLocale(_: unknown, t: TFunction): void {
  yup.setLocale({
    mixed: {
      required: i18n.t("greco.form.errors.required"),
    },
    string: {
      min: (state) => {
        return i18n.t("greco.form.errors.string.min", { min: state.min });
      },
      max: (state) => {
        return i18n.t("greco.form.errors.string.max", { max: state.max });
      },
    },
    number: {
      min: i18n.t("greco.form.errors.number.min"),
      max: i18n.t("greco.form.errors.number.max"),
    },
    array: {
      min: (state) => {
        return i18n.t("greco.form.errors.array.min", { min: state.min });
      },
    },
  });
}
