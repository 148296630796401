import { MUTATION_KEYS } from "library/shared";
import { IDependent } from "library/types";
import { handleAxiosError } from "library/utils";
import { useMutation } from "react-query";
import api from "service/api";
import { useInvalidateDependentData } from "./useGetDependentData";

type Params = {
  countryCode: number;
  clientInternalNumberGOS: number;
  employeeDependentTag: number;
  payload: IDependent;
};

const updateDependent = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeDependentTag,
  payload,
}: Params) => {
  const response = await api.patch(
    `/employee/dependent/${countryCode}/${clientInternalNumberGOS}/${employeeDependentTag}`,
    payload
  );
  return response.data as IDependent;
};

export function useUpdateDependent(refetch = true) {
  const invalidateDependentData = useInvalidateDependentData();

  return useMutation(updateDependent, {
    mutationKey: MUTATION_KEYS.updateDependent,
    onSuccess: () => {
      if (refetch) {
        invalidateDependentData();
      }
    },
    onError: (error) => {
      // handle error
      handleAxiosError(error);
    },
  });
}
