import { IStyledTheme } from "../types";

export const styledTheme: IStyledTheme = {
  name: "main-theme-light",
  palette: {
    themeDarker: "#00335a",
    themeDark: "#00457a",
    themeDarkAlt: "#005291",
    themePrimary: "#005aa1",
    themeSecondary: "#156aac",
    themeTertiary: "#4f93c6",
    themeLight: "#9fc5e3",
    themeLighter: "#cadff0",
    themeLighterAlt: "#f1f7fb",
    black: "#000000",
    blackTranslucent40: "rgba(0,0,0,.1)",
    themeBackground: "#f8f9fe",
    neutralDark: "#201f1e",
    neutralPrimary: "#323130",
    neutralPrimaryAlt: "#3b3a39",
    neutralSecondary: "#605e5c",
    neutralSecondaryAlt: "#8a8886",
    neutralTertiary: "#a19f9d",
    neutralTertiaryAlt: "#c8c6c4",
    neutralQuaternary: "#d0d0d0",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralLight: "#edebe9",
    neutralLighter: "#f3f2f1",
    neutralLighterAlt: "#faf9f8",
    accent: "#005aa1",
    white: "#ffffff",
    whiteTranslucent40: "rgba(255,255,255,.1)",
    yellowDark: "#d29200",
    yellow: "#ffb900",
    yellowLight: "#fff100",
    orange: "#d83b01",
    orangeLight: "#ea4300",
    orangeLighter: "#ff8c00",
    redDark: "#a4262c",
    red: "#e81123",
    magentaDark: "#5c005c",
    magenta: "#b4009e",
    magentaLight: "#e3008c",
    purpleDark: "#32145a",
    purple: "#5c2d91",
    purpleLight: "#b4a0ff",
    blueDark: "#002050",
    blueMid: "#00188f",
    blue: "#0078d4",
    blueLight: "#00bcf2",
    tealDark: "#004b50",
    teal: "#008272",
    tealLight: "#00b294",
    greenDark: "#004b1c",
    green: "#107c10",
    greenLight: "#bad80a",
    titanWhite: "#eeeeff",
    //
  },
  effects: {
    elevation4:
      "0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108)",
    elevation8:
      "0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108)",
    elevation16:
      "0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108)",
    elevation64:
      "0 25.6px 57.6px 0 rgba(0, 0, 0, 0.22), 0 4.8px 14.4px 0 rgba(0, 0, 0, 0.18)",
    roundedCorner2: "2px",
    roundedCorner4: "4px",
    roundedCorner6: "6px",
  },

  semanticColors: {
    bodyBackground: "#ffffff",
    bodyFrameBackground: "#ffffff",
    accentButtonText: "#ffffff",
    buttonBackground: "#ffffff",
    primaryButtonText: "#ffffff",
    primaryButtonTextHovered: "#ffffff",
    primaryButtonTextPressed: "#ffffff",
    inputBackground: "#ffffff",
    inputForegroundChecked: "#ffffff",
    listBackground: "#ffffff",
    menuBackground: "#ffffff",
    cardStandoutBackground: "#ffffff",
    bodyTextChecked: "#000000",
    buttonTextCheckedHovered: "#000000",
    link: "#005aa1",
    primaryButtonBackground: "#005aa1",
    inputBackgroundChecked: "#005aa1",
    inputIcon: "#005aa1",
    inputFocusBorderAlt: "#005aa1",
    menuIcon: "#005aa1",
    menuHeader: "#005aa1",
    accentButtonBackground: "#005aa1",
    primaryButtonBackgroundPressed: "#00457a",
    inputBackgroundCheckedHovered: "#00457a",
    inputIconHovered: "#00457a",
    linkHovered: "#00335a",
    primaryButtonBackgroundHovered: "#005291",
    inputPlaceholderBackgroundChecked: "#cadff0",
    bodyBackgroundChecked: "#edebe9",
    bodyFrameDivider: "#edebe9",
    bodyDivider: "#edebe9",
    variantBorder: "#edebe9",
    buttonBackgroundCheckedHovered: "#edebe9",
    buttonBackgroundPressed: "#edebe9",
    listItemBackgroundChecked: "#edebe9",
    listHeaderBackgroundPressed: "#edebe9",
    menuItemBackgroundPressed: "#edebe9",
    menuItemBackgroundChecked: "#edebe9",
    bodyBackgroundHovered: "#f3f2f1",
    buttonBackgroundHovered: "#f3f2f1",
    buttonBackgroundDisabled: "#f3f2f1",
    buttonBorderDisabled: "#f3f2f1",
    primaryButtonBackgroundDisabled: "#f3f2f1",
    disabledBackground: "#f3f2f1",
    listItemBackgroundHovered: "#f3f2f1",
    listHeaderBackgroundHovered: "#f3f2f1",
    menuItemBackgroundHovered: "#f3f2f1",
    primaryButtonTextDisabled: "#d0d0d0",
    disabledSubtext: "#d0d0d0",
    listItemBackgroundCheckedHovered: "#e1dfdd",
    disabledBodyText: "#a19f9d",
    variantBorderHovered: "#a19f9d",
    buttonTextDisabled: "#a19f9d",
    inputIconDisabled: "#a19f9d",
    disabledText: "#a19f9d",
    bodyText: "#323130",
    actionLink: "#323130",
    buttonText: "#323130",
    inputBorderHovered: "#323130",
    inputText: "#323130",
    listText: "#323130",
    menuItemText: "#323130",
    bodyStandoutBackground: "#faf9f8",
    defaultStateBackground: "#faf9f8",
    actionLinkHovered: "#201f1e",
    buttonTextHovered: "#201f1e",
    buttonTextChecked: "#201f1e",
    buttonTextPressed: "#201f1e",
    inputTextHovered: "#201f1e",
    menuItemTextHovered: "#201f1e",
    bodySubtext: "#605e5c",
    focusBorder: "#605e5c",
    inputBorder: "#605e5c",
    smallInputBorder: "#605e5c",
    inputPlaceholderText: "#605e5c",
    buttonBorder: "#8a8886",
    disabledBodySubtext: "#c8c6c4",
    disabledBorder: "#c8c6c4",
    buttonBackgroundChecked: "#c8c6c4",
    menuDivider: "#c8c6c4",
    cardShadow:
      "0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108)",
    cardShadowHovered: "0 0 1px #a19f9d",
    primaryButtonBorder: "transparent",
    errorText: "#a4262c",
    messageText: "#323130",
    messageLink: "#005A9E",
    messageLinkHovered: "#004578",
    infoIcon: "#605e5c",
    errorIcon: "#A80000",
    blockingIcon: "#FDE7E9",
    warningIcon: "#797775",
    severeWarningIcon: "#D83B01",
    successIcon: "#107C10",
    infoBackground: "#f3f2f1",
    errorBackground: "#FDE7E9",
    blockingBackground: "#D77F7F",

    warningBackground: "#FFF4CE",
    severeWarningBackground: "#FED9CC",
    successBackground: "#DFF6DD",
    warningHighlight: "#ffb900",
    // successHighlight: "#ACE1AF",
    successHighlight: "#9FD7B2",
    successText: "#107C10",
    listTextColor: "#323130",
    warningText: "#323130",
  },
  isDark: false,
};

export const darkStyledTheme: IStyledTheme = {
  name: "main-theme-dark",

  palette: {
    themeDarker: "#f1f7fb",
    themeDark: "#cadff0",
    themeDarkAlt: "#9fc5e3",
    themePrimary: "#4f93c6",
    themeSecondary: "#3b79b5",
    themeTertiary: "#2a5f94",
    themeLight: "#1c4769",
    themeLighter: "#11304d",
    themeLighterAlt: "#0a1a31",
    black: "#ffffff",
    blackTranslucent40: "rgba(255,255,255,.4)",
    themeBackground: "#323130",
    neutralDark: "#faf9f8",
    neutralPrimary: "#f3f2f1",
    neutralPrimaryAlt: "#edebe9",
    neutralSecondary: "#e1dfdd",
    neutralSecondaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralTertiaryAlt: "#8a8886",
    neutralQuaternary: "#605e5c",
    neutralQuaternaryAlt: "#3b3a39",
    neutralLight: "#323130",
    neutralLighter: "#201f1e",
    neutralLighterAlt: "#201f1e",
    accent: "#4f93c6",
    white: "#000000",
    whiteTranslucent40: "rgba(0,0,0,.4)",
    yellowDark: "#ffedcc",
    yellow: "#ffdb99",
    yellowLight: "#ffd966",
    orange: "#ffa64d",
    orangeLight: "#ff8533",
    orangeLighter: "#ff6600",
    redDark: "#ff9999",
    red: "#ff4d4d",
    magentaDark: "#ffccff",
    magenta: "#ff99ff",
    magentaLight: "#ff66ff",
    purpleDark: "#f3e0ff",
    purple: "#d6b3ff",
    purpleLight: "#c399ff",
    blueDark: "#e0f0ff",
    blueMid: "#99ccff",
    blue: "#4da6ff",
    blueLight: "#1a8cff",
    tealDark: "#cceeff",
    teal: "#80d4ff",
    tealLight: "#33ccff",
    greenDark: "#ccffe6",
    green: "#66ffcc",
    greenLight: "#00e699",
    titanWhite: "#111111",
  },
  effects: {
    elevation4:
      "0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108)",
    elevation8:
      "0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108)",
    elevation16:
      "0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108)",
    elevation64:
      "0 25.6px 57.6px 0 rgba(0, 0, 0, 0.22), 0 4.8px 14.4px 0 rgba(0, 0, 0, 0.18)",
    roundedCorner2: "2px",
    roundedCorner4: "4px",
    roundedCorner6: "6px",
  },

  semanticColors: {
    bodyBackground: "#171717",
    bodyFrameBackground: "#171717",
    accentButtonText: "#171717",
    buttonBackground: "#171717",
    primaryButtonText: "#171717",
    primaryButtonTextHovered: "#171717",
    primaryButtonTextPressed: "#171717",
    inputBackground: "#171717",
    inputForegroundChecked: "#171717",
    listBackground: "#171717",
    menuBackground: "#171717",
    cardStandoutBackground: "#171717",
    bodyTextChecked: "#f8f8f8",
    buttonTextCheckedHovered: "#f8f8f8",
    link: "#38a9ff",
    primaryButtonBackground: "#38a9ff",
    inputBackgroundChecked: "#38a9ff",
    inputIcon: "#38a9ff",
    inputFocusBorderAlt: "#38a9ff",
    menuIcon: "#38a9ff",
    menuHeader: "#38a9ff",
    accentButtonBackground: "#38a9ff",
    primaryButtonBackgroundPressed: "#68bdff",
    inputBackgroundCheckedHovered: "#68bdff",
    inputIconHovered: "#68bdff",
    linkHovered: "#90cfff",
    primaryButtonBackgroundHovered: "#4cb1ff",
    inputPlaceholderBackgroundChecked: "#091b29",
    bodyBackgroundChecked: "#393939",
    bodyFrameDivider: "#393939",
    bodyDivider: "#393939",
    variantBorder: "#393939",
    buttonBackgroundCheckedHovered: "#393939",
    buttonBackgroundPressed: "#393939",
    listItemBackgroundChecked: "#393939",
    listHeaderBackgroundPressed: "#393939",
    menuItemBackgroundPressed: "#393939",
    menuItemBackgroundChecked: "#393939",
    bodyBackgroundHovered: "#2a2a2a",
    buttonBackgroundHovered: "#2a2a2a",
    buttonBackgroundDisabled: "#2a2a2a",
    buttonBorderDisabled: "#2a2a2a",
    primaryButtonBackgroundDisabled: "#2a2a2a",
    disabledBackground: "#2a2a2a",
    listItemBackgroundHovered: "#2a2a2a",
    listHeaderBackgroundHovered: "#2a2a2a",
    menuItemBackgroundHovered: "#2a2a2a",
    primaryButtonTextDisabled: "#494949",
    disabledSubtext: "#494949",
    listItemBackgroundCheckedHovered: "#424242",
    disabledBodyText: "#c8c8c8",
    variantBorderHovered: "#c8c8c8",
    buttonTextDisabled: "#c8c8c8",
    inputIconDisabled: "#c8c8c8",
    disabledText: "#c8c8c8",
    bodyText: "#ffffff",
    actionLink: "#ffffff",
    buttonText: "#ffffff",
    inputBorderHovered: "#ffffff",
    inputText: "#ffffff",
    listText: "#ffffff",
    menuItemText: "#ffffff",
    bodyStandoutBackground: "#212121",
    defaultStateBackground: "#212121",
    actionLinkHovered: "#f4f4f4",
    buttonTextHovered: "#f4f4f4",
    buttonTextChecked: "#f4f4f4",
    buttonTextPressed: "#f4f4f4",
    inputTextHovered: "#f4f4f4",
    menuItemTextHovered: "#f4f4f4",
    bodySubtext: "#d0d0d0",
    focusBorder: "#d0d0d0",
    inputBorder: "#d0d0d0",
    smallInputBorder: "#d0d0d0",
    inputPlaceholderText: "#d0d0d0",
    buttonBorder: "#8a8886",
    disabledBodySubtext: "#686868",
    disabledBorder: "#686868",
    buttonBackgroundChecked: "#686868",
    menuDivider: "#686868",
    cardShadow:
      "0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108)",
    cardShadowHovered: "0 0 1px #c8c8c8",
    primaryButtonBorder: "transparent",
    errorText: "#a4262c",
    messageText: "#323130",
    messageLink: "#005A9E",
    messageLinkHovered: "#004578",
    infoIcon: "#605e5c",
    errorIcon: "#A80000",
    blockingIcon: "#FDE7E9",
    warningIcon: "#797775",
    severeWarningIcon: "#D83B01",
    successIcon: "#107C10",
    infoBackground: "#f3f2f1",
    errorBackground: "#FDE7E9",
    blockingBackground: "#8A4F4F",
    warningBackground: "#FFF4CE",
    severeWarningBackground: "#FED9CC",
    successBackground: "#DFF6DD",
    warningHighlight: "#ffb900",
    successHighlight: "#50B498",

    successText: "#107C10",
    listTextColor: "#323130",
    warningText: "#323130",
  },
  isDark: true,
};
