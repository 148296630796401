import { Card, ToggleButton } from "@fluentui/react-components";
import {
  DocumentRegular,
  InfoRegular,
  PeopleRegular,
} from "@fluentui/react-icons";
import useEmployee from "hooks/useEmployee";
import { useAtom } from "jotai";
import { devices } from "library/constants";
import { EProfileTab } from "library/types";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { selectedProfileTabAtom } from "store/UIProfilePage";
import styled from "styled-components";

export function ProfileHeader() {
  const { t } = useTranslation();
  const { employeeData } = useEmployee();

  const [selectedTab, setSelectedTab] = useAtom(selectedProfileTabAtom);

  const onSelectTab = useCallback((tab: EProfileTab) => {
    setSelectedTab(tab);
  }, []);

  return (
    <Container>
      <ImageWrap>
        <img src={employeeData?.backgroundPicturePath} alt="user-profile" />
      </ImageWrap>
      <ButtonsWrap>
        <ToggleButton
          checked={selectedTab === EProfileTab.info}
          appearance="subtle"
          onClick={() => {
            onSelectTab(EProfileTab.info);
          }}
          icon={<InfoRegular />}
        >
          {t("hbh.info.label")}
        </ToggleButton>
        <ToggleButton
          checked={selectedTab === EProfileTab.dependents}
          appearance="subtle"
          icon={<PeopleRegular />}
          onClick={() => {
            onSelectTab(EProfileTab.dependents);
          }}
        >
          {t("hbh.dependents.label")}
        </ToggleButton>
        <ToggleButton
          checked={selectedTab === EProfileTab.documents}
          appearance="subtle"
          icon={<DocumentRegular />}
          onClick={() => {
            onSelectTab(EProfileTab.documents);
          }}
        >
          {t("hbh.documents.label")}
        </ToggleButton>
      </ButtonsWrap>
    </Container>
  );
}

const Container = styled(Card)`
  margin: 50px auto !important;
  display: flex !important;
  align-items: flex-end !important;
  justify-content: center !important;
  padding: 10px;
  position: relative;
  width: 100%;
  // background-color: ${({ theme }) => theme.palette.white};
  min-height: 100px;

  @media only screen and ${devices.md} {
    align-items: center;
    justify-content: flex-end;
    margin: 100px auto;
    max-width: 1800px;
    margin-top: 100px;
    padding: 0 10px;
  }
`;

const ImageWrap = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  img {
    width: 100px;
    height: 100px;
    border: 1px solid ${({ theme }) => theme.palette.themePrimary};
    padding: 2px;
    top: -40px;
    border-radius: 50%;
    z-index: 3;
  }

  @media only screen and ${devices.md} {
    left: 100px;
    transform: translate(-50%, -80%);

    img {
      width: 130px;
      height: 130px;
      border: 1px solid ${({ theme }) => theme.palette.themePrimary};
      padding: 5px;
      border-radius: 50%;
    }
  }
`;

const ButtonsWrap = styled.div`
  display: flex;
  gap: 5px;
  height: 30px;
  button {
    min-width: auto;
    padding: 0 5px;
  }
  @media only screen and ${devices.md} {
    padding-right: 100px;
  }
`;
