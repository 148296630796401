import { MUTATION_KEYS } from "library/shared";
import { IEmployeeEnrollment } from "library/types";
import { handleAxiosError } from "library/utils";
import { useMutation } from "react-query";
import api from "service/api";
import { useInvalidateEmployeeEnrollments } from "./useGetEnrollments";

type Params = {
  countryCode: number;
  clientInternalNumberGOS: number;
  benefitPackageTag: number;
  employeeEnrollmentTag: number;
  employeeDependentTag?: number;
  dateOfCancellation: string;
  payload: {
    reasonForCancellation: string;
  };
};

const cancelEnrollment = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitPackageTag,
  employeeEnrollmentTag,
  employeeDependentTag = 0,
  dateOfCancellation,
  payload,
}: Params) => {
  const response = await api.patch(
    `/employeeenrollment/cancel/${countryCode}/${clientInternalNumberGOS}/${benefitPackageTag}/${employeeDependentTag}/${employeeEnrollmentTag}/${dateOfCancellation}`,
    payload
  );

  return response.data as IEmployeeEnrollment;
};

export function useCancelEnrolment(refetch = true) {
  const invalidateEmployeeEnrollments = useInvalidateEmployeeEnrollments();
  return useMutation(cancelEnrollment, {
    mutationKey: MUTATION_KEYS.cancelEnrollment,
    onSuccess: () => {
      if (refetch) {
        invalidateEmployeeEnrollments();
      }
    },
    onError: (error) => {
      // handle error
      handleAxiosError(error);
    },
  });
}
