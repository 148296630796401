import { QUERY_KEYS } from "library/shared";
import { IClient } from "library/types";
import { handleAxiosError } from "library/utils";
import { UseQueryResult, useQuery } from "react-query";
import api from "service/api";

const getUserClients = async () => {
  const response = await api.get(`/client/b2c`);

  return response.data;
};

export function useGetUserClients(enabled = true): UseQueryResult<IClient[]> {
  return useQuery<IClient[], Error>([QUERY_KEYS.userClients], getUserClients, {
    enabled: enabled,
    onError: (error: any) => {
      handleAxiosError(error);
    },
  });
}
