import { components } from "swagger/types";

export interface SupportedLanguage {
  isGrECoLanguage: boolean;
  code: string;
  isDeleted: boolean;
  id: number;
}

export type TaxonomyKey = any;

export type Taxonomy = {
  code: string;
  id: number;
};

export enum ETaxonomy {
  ConsentType = "ConsentType",
  Country = "Country",
  Currency = "Currency",
  DependentType = "DependentType",
  EligibleType = "EligibleType",
  PaymentMethod = "PaymentMethod",
  PaymentType = "PaymentType",
  EligibleDateType = "EligibleDateType",
  OffboardingType = "OffboardingType",
  OffboardingDateType = "OffboardingDateType",
  Gender = "Gender",
  SicCode = "SicCode",
}

export interface INotification {
  fieldName?: string;
  text: string;
  id: string;
  handleClearNotification?: (message: INotification) => void;
  type: "error" | "info" | "warning" | "success";
  // notify: INotify;
}

export interface INotify {
  notifyText: string;
  type: string;
  notifyCode: string;
  label: string;
}

export interface IErrorHandler {
  value: string;
  type: string;
  label: string;
  fieldName?: string;
  errors?: any;
}

export interface IUser {
  // "@odata.context"?: string;
  // businessPhones?: any[];
  // displayName?: string;
  // givenName?: string;
  // jobTitle?: string | null;
  mail?: string | null;
  // mobilePhone?: string;
  // officeLocation?: string | null;
  // preferredLanguage?: string;
  // surname?: string;
  // userPrincipalName?: string;
  // id?: string;
  // photoMedium?: string;
}

export enum ECheckedValue {
  Yes = "yes",
  No = "no",
}

export enum EPaidBy {
  Percentage = "Percentage",
  Amount = "Amount",
}

export type SelectItem = {
  text: string;
  value: string | number;
  Icon?: JSX.Element;
  [x: string]: any;
};

export type TBenefitFields = {
  benefit: string;
};

export type IFormattedFile = {
  name: string | null;
  path?: string | null;
  base64String: string | null;
  type: string | null;
};

export type IGrecoClient = {
  id: number;
  internalNumber: number;
  name: string;
  countryCodeId: number;
  isCompany?: boolean;
  label?: string;
  value?: string;
};

export interface IBackendError {
  statusCode: number;
  errorCode: string;
  type: string;
  correlationID: string;
  messageCode: string;
  messageVariables: any[];
  statusDescription: string;
  message: string;
  formatedMessage: string;
  exceptionMessage: string;
  stackTrace: string;
}

export interface IInsurer {
  Id: number;
  InternalNumber: number;
  Name: string;
  CountryCodeId: number;
  IsCompany: boolean;
}

export enum EBenefitContentTab {
  eligible = 1,
  enrolled = 2,
}

export enum EProfileTab {
  info = "info",
  dependents = "dependents",
  documents = "documents",
}

export interface ITaxonomy
  extends Partial<components["schemas"]["TaxonomyData"]> {}

export interface IClient extends Partial<components["schemas"]["ClientB2C"]> {}
export interface IEmployee extends Partial<components["schemas"]["Employee"]> {}

export interface IDependent
  extends Partial<components["schemas"]["EmployeeDependentProfile"]> {}

export interface ICompanyPackageView
  extends Partial<components["schemas"]["BenefitPackageView"]> {}

export interface ICardBenefitPackage extends ICompanyPackageView {
  enrolled?: boolean;
  isDependent?: boolean;
  employeeLoginEmailAddress?: string;
  employeeDependentTag?: number;
  cancellationPossibility?: string;
  employeeEnrollmentTag?: number;
  dateOfCancellation?: string;
}
export interface IEmployeeEnrollment
  extends Partial<components["schemas"]["EmployeeEnrollment"]> {}
