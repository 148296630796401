import { MUTATION_KEYS } from "library/shared";
import { handleAxiosError } from "library/utils";
import { useMutation } from "react-query";
import api from "service/api";
import { useInvalidateEmployeeData } from "./useGetEmployeeData";
import { IDependent } from "library/types";

type Params = {
  countryCode: number;
  clientInternalNumberGOS: number;
  payload: IDependent;
};

const createDependent = async ({
  countryCode,
  clientInternalNumberGOS,
  payload,
}: Params) => {
  const response = await api.post(
    `/employee/dependent/${countryCode}/${clientInternalNumberGOS}`,
    payload
  );
  return response.data as IDependent;
};

export function useCreateDependent(refetch = true) {
  const invalidateEmployeeData = useInvalidateEmployeeData();
  return useMutation(createDependent, {
    mutationKey: MUTATION_KEYS.createDependent,
    onSuccess: () => {
      if (refetch) {
        invalidateEmployeeData();
      }
    },
    onError: (error) => {
      // handle error
      handleAxiosError(error);
    },
  });
}
