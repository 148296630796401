import { QUERY_KEYS } from "library/shared";
import { handleAxiosError } from "library/utils";
import { useQuery, UseQueryResult } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number;
  clientInternalNumberGOS: number;
  benefitPackageTag: number;
  employeeEnrollmentTag: number;
  employeeDependentTag?: number;
  dateOfEnrollment: string;
};

export const getEnrollmentCancellationPossibilityDate = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitPackageTag,
  employeeEnrollmentTag,
  employeeDependentTag = 0,
  dateOfEnrollment,
}: Params) => {
  const response = await api.get(
    `/employeeenrollment/cancellationpossibility/${countryCode}/${clientInternalNumberGOS}/${benefitPackageTag}/${employeeDependentTag}/${employeeEnrollmentTag}/${dateOfEnrollment}`
  );

  return response.data as string;
};

export function useGetEnrollmentCancellationPossibilityDate({
  countryCode,
  clientInternalNumberGOS,
  benefitPackageTag,
  employeeDependentTag = 0,
  employeeEnrollmentTag,
  dateOfEnrollment,
}: Params): UseQueryResult<string> {
  return useQuery<string, Error>(
    [
      QUERY_KEYS.enrollmentCancellationPossibilityDate,
      countryCode,
      clientInternalNumberGOS,
      benefitPackageTag,
      employeeDependentTag,
      employeeEnrollmentTag,
      dateOfEnrollment,
    ],
    () => {
      return getEnrollmentCancellationPossibilityDate({
        countryCode,
        clientInternalNumberGOS,
        benefitPackageTag,
        employeeEnrollmentTag,
        employeeDependentTag,
        dateOfEnrollment,
      });
    },
    {
      onError: (error: any) => {
        handleAxiosError(error);
      },
    }
  );
}
