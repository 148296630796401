import { useAtom } from "jotai";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { INotification } from "../library/types";
import { notificationMessagesAtom } from "../store";

export function useNotificationHandler() {
  const [notifications, setNotifications] = useAtom(notificationMessagesAtom);

  const handleClearNotification = useCallback(
    (message: INotification) => {
      setNotifications((prev) => {
        return prev?.filter((item) => item.id !== message.id);
      });
    },
    [setNotifications]
  );

  const handleAddNotification = useCallback(
    (newNotification: INotification) => {
      toast[newNotification.type](newNotification.text);
      setNotifications((prev: INotification[]) => {
        const alreadyExist = prev.some(
          (item) => item.id === newNotification.id
        );

        if (alreadyExist) {
          return [...prev];
        }
        return [
          ...prev,
          {
            ...newNotification,
            handleClearNotification: handleClearNotification,
          },
        ];
      });
    },
    [handleClearNotification, setNotifications]
  );

  const handleClearAllNotifications = useCallback(() => {
    setNotifications([]);
  }, [setNotifications]);

  return {
    handleAddNotification,
    notifications,
    handleClearAllNotifications,
  };
}

export default useNotificationHandler;
