import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
} from "@fluentui/react-components";
import {
  Dismiss24Regular,
  PersonRegular,
  SaveRegular,
} from "@fluentui/react-icons";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { Flex } from "components/styled";
import { Form, Formik } from "formik";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { devices } from "library/constants";
import { ETaxonomy, IEmployee } from "library/types";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as yup from "yup";
import { EmployeeFormFields } from "./UpdateEmployeeFields/EmployeeFormFields/EmployeeFormFields";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { useUpdateEmployee } from "state/useUpdateEmployee";
import { useAtomValue } from "jotai";
import { selectedClientAtom } from "store";
import {
  formatDateFieldForPayload,
  formatSelectFieldForPayload,
} from "library/utils";
import { toast } from "react-toastify";
import { useGetMsalUserEmail } from "hooks/useGetMsalUserEmail";
import { CancelIcon } from "@fluentui/react-icons-mdl2";
import { AppLogoSpinner } from "components/AppLogoSpinner/AppLogoSpinner";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  item: IEmployee;
};

export function EmployeeFormDialog({ open, setOpen, item }: Props) {
  const { t } = useTranslation();

  const loginMail = useGetMsalUserEmail();

  const selectedClient = useAtomValue(selectedClientAtom);
  const genderOptions = useTaxonomyOptions(ETaxonomy.Gender);
  const countryOptions = useTaxonomyOptions(ETaxonomy.Country);
  const { mutate: onUpdateEmployee, isLoading: updateEmployeeInProgress } =
    useUpdateEmployee();

  const styles = useStyles();

  const handleSubmit = useCallback(
    (values) => {
      onUpdateEmployee(
        {
          countryCode: Number(selectedClient?.countryCode),
          clientInternalNumberGOS: Number(
            selectedClient?.clientInternalNumberGos
          ),
          payload: {
            ...values,
            genderCodeId: formatSelectFieldForPayload({
              values,
              field: "genderCodeId",
            }),
            citizenshipCodeId: formatSelectFieldForPayload({
              values,
              field: "citizenshipCodeId",
            }),

            dateOfBirth: formatDateFieldForPayload(values.dateOfBirth),
            ...(values.employeeCoverPhoto?.base64String && {
              backgroundPictureContent: values.employeeCoverPhoto?.base64String,
              backgroundPictureName: values.employeeCoverPhoto?.name,
              backgroundPicturePath: values.employeeCoverPhoto?.path,
              backgroundPictureMimeType: values.employeeCoverPhoto?.type,
            }),

            backgroundPicturePath: values.employeeCoverPhoto?.path,
            dependents: values.dependents.map((dependent) => {
              return {
                ...dependent,
                rowVersion: dependent.rowVersion || item?.rowVersion,
              };
            }),
          },
        },
        {
          onSuccess() {
            toast.success(t("hbh.employeeUpdatedSuccessfully.label"));
            setOpen(false);
          },
        }
      );
    },
    [selectedClient, item]
  );

  const initialValues = useMemo(() => {
    return {
      //main
      ...item,
      dependents:
        (item?.dependents as IEmployee["dependents"])?.map((dependent) => {
          return {
            ...dependent,
            dependentCoverPhoto: dependent?.backgroundPicturePath
              ? {
                  name: dependent?.backgroundPictureName,
                  path: dependent?.backgroundPicturePath,
                  base64String: dependent?.backgroundPictureContent,
                  type: dependent?.backgroundPictureMimeType,
                }
              : null,
          };
        }) || [],

      //basic
      genderCodeId: genderOptions.find(
        (genderCode) => genderCode.value === item?.genderCodeId
      ),
      citizenshipCodeId: countryOptions.find(
        (country) => country.value === item?.citizenshipCodeId
      ),

      employeeCoverPhoto: item?.backgroundPicturePath
        ? {
            name: item?.backgroundPictureName,
            path: item?.backgroundPicturePath,
            base64String: item?.backgroundPictureContent,
            type: item?.backgroundPictureMimeType,
          }
        : null,
    };
  }, [item, genderOptions, countryOptions]);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      emailAddress: yup.string().required(),
      mobilePhoneNumber: yup.string().required(),
      dateOfBirth: yup.string().required(),

      dependents: yup.array().of(
        yup.object().shape({
          dependentType: yup.mixed().required(),
          firstName: yup.string().required(),
          lastName: yup.string().required(),
        })
      ),
    });
  }, []);

  if (!item) {
    return <AppLogoSpinner />;
  }

  return (
    <Dialog
      modalType="alert"
      open={open}
      onOpenChange={(event, data) => setOpen(data.open)}
    >
      <StyledDialogSurface
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {updateEmployeeInProgress && <AppLogoSpinner />}
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            handleSubmit(values);
            actions.setSubmitting(false);
          }}
        >
          {() => {
            return (
              <Form>
                <DialogBody className={styles.content}>
                  <DialogTitle
                    action={
                      <DialogTrigger action="close">
                        <Button
                          appearance="subtle"
                          aria-label="close"
                          icon={<Dismiss24Regular />}
                        />
                      </DialogTrigger>
                    }
                  >
                    <Flex width={"100%"} justify="flex-start">
                      <PersonRegular />
                      <span>{`${item.firstName} ${item.lastName}`} </span>
                      <span>({loginMail})</span>
                    </Flex>
                  </DialogTitle>
                  <DialogContent className={styles.content}>
                    <EmployeeFormFields employeeDetails={item} />
                  </DialogContent>
                  <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                      <Button
                        appearance="secondary"
                        icon={
                          <CancelIcon
                            style={{
                              fontSize: 16,
                            }}
                          />
                        }
                      >
                        {t("greco.cancel")}
                      </Button>
                    </DialogTrigger>
                    <PrimaryButton
                      icon={<SaveRegular />}
                      style={{
                        paddingLeft: "25px",
                        paddingRight: "25px",
                      }}
                    >
                      {t("greco.save")}
                    </PrimaryButton>
                  </DialogActions>
                </DialogBody>
              </Form>
            );
          }}
        </Formik>
      </StyledDialogSurface>
    </Dialog>
  );
}

const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;

  @media only screen and ${devices.md} {
    width: 1200px;
    min-width: 1200px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;

const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
    width: "1200px",
  },
  content: {
    maxHeight: "90vh",
    scrollbarWidth: "thin",
  },
});
