import styled, { ThemeProvider } from "styled-components";

import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import { AppLogoSpinner } from "components/AppLogoSpinner/AppLogoSpinner";
import { styledTheme } from "theme/mainTheme";
import grecoTriangleBg from "../../assets/GrECoGraphicTriangles.svg";

export function FallbackSpinner() {
  return (
    <Container>
      <FluentProvider theme={webLightTheme}>
        <ThemeProvider theme={styledTheme}>
          <AppLogoSpinner />
        </ThemeProvider>
      </FluentProvider>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  overflow: auto;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  algin-items: center;
  background-image: url("${grecoTriangleBg}");
  background-size: auto;
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
`;
