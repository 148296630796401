import { useAtomValue } from "jotai";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  styled,
  ThemeProvider as StyledThemeProvider,
} from "styled-components";
import "./App.css";
import AppHeader from "./components/AppHeader/AppHeader";

import {
  darkModeAtom,
  panelIsOpenState,
  selectedClientAtom,
  selectedThemeAtom,
} from "./store";

import {
  FluentProvider,
  webDarkTheme,
  webLightTheme,
} from "@fluentui/react-components";

import { useEffect, useState } from "react";

import { AppLogoSpinner } from "components/AppLogoSpinner/AppLogoSpinner";
import { AppNavigation } from "components/AppNavigation/AppNavigation";
import useEmployee from "hooks/useEmployee";
import { useInitSelectClient } from "hooks/useInitSelectClient";
import { devices, ROUTES, sizes } from "library/constants";
import { BenefitsPage } from "pages/HomePage/BenefitsPage/BenefitsPage";
import { useGetTaxonomies } from "state/useGetTaxonomies";
import { allThemes } from "theme";
import { EThemeMode, IStyledTheme } from "theme/types";
import grecoTriangleBg from "./assets/GrECoGraphicTriangles.svg";
import { ProfilePage } from "pages/ProfilePage/ProfilePage";

function App() {
  useInitSelectClient();
  useGetTaxonomies();
  useEmployee();

  const panelIsOpen = useAtomValue(panelIsOpenState);
  const darkMode = useAtomValue(darkModeAtom);
  const selectedClient = useAtomValue(selectedClientAtom);

  const selectedThemeName = useAtomValue(selectedThemeAtom);
  const [appTheme, setAppTheme] = useState<IStyledTheme>(
    allThemes[selectedThemeName][darkMode ? EThemeMode.dark : EThemeMode.light]
  );

  const [fluentTheme, setFluentTheme] = useState(
    darkMode ? webDarkTheme : webLightTheme
  );

  useEffect(() => {
    const fluentTheme = darkMode ? webDarkTheme : webLightTheme;
    setAppTheme(
      allThemes[selectedThemeName][
        darkMode ? EThemeMode.dark : EThemeMode.light
      ]
    );
    setFluentTheme(fluentTheme);
  }, [darkMode, selectedThemeName]);

  // on mount

  return (
    <div className="App">
      <FluentProvider theme={fluentTheme}>
        <StyledThemeProvider theme={appTheme}>
          {!selectedClient && <AppLogoSpinner />}
          <BrowserRouter>
            <AppHeader />
            <AppNavigation />
            <Container $isPanelOpen={panelIsOpen}>
              <Routes>
                <Route path={ROUTES.PROFILE} element={<ProfilePage />} />
                <Route path={ROUTES.BENEFITS} element={<BenefitsPage />} />
                <Route
                  path="*"
                  element={<Navigate to={ROUTES.BENEFITS} replace />}
                />
              </Routes>
            </Container>
            <ToastContainer
              style={{
                zIndex: 9999999,
              }}
            />
          </BrowserRouter>
        </StyledThemeProvider>
      </FluentProvider>
    </div>
  );
}

export default App;

const Container = styled("div")<{ $isPanelOpen: boolean }>`
  position: absolute;
  overflow: auto;
  top: ${sizes.appHeader}px; //50px header
  left: 0;
  bottom: ${sizes.appNavHeightMobile}px; //footer
  right: 0;
  // width: ${({ $isPanelOpen }) =>
    $isPanelOpen ? "calc(100% - 340px)" : "100%"};
  width: 100%;
  background-color: ${(props) => props.theme.palette.themeBackground};

  background-image: url("${grecoTriangleBg}");
  background-size: auto;
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;

  @media only screen and ${devices.md} {
    top: ${sizes.appHeader +
    sizes.appNavHeightDesktop}px; //50px header  + 40px navigation
    bottom: 0;
  }
`;
