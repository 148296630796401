import { ArrowExportRegular } from "@fluentui/react-icons";
import { ActionButton, Grid } from "components/styled";
import { IEmployee } from "library/types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { EmployeeDependentsDialog } from "../EmployeeDependentsDialog/EmployeeDependentsDialog";
import { BasicFields } from "../FormFields/BasicFields";
import { MainFields } from "../FormFields/MainFields";

type Props = {
  employeeDetails?: IEmployee;
};

export function EmployeeFormFields({ employeeDetails }: Props) {
  const { t } = useTranslation();

  const [manageDependentsOpen, setManageDependentsOpen] = useState(false);

  const onManageDependentsOpen = useCallback((state) => {
    setManageDependentsOpen(state);
  }, []);

  const employeeDependentsNumber = employeeDetails?.dependents?.length;
  return (
    <>
      <Container>
        <MainFields isAddMode={false} />
        <BasicFields />

        <Grid>
          <ActionButton
            icon={<ArrowExportRegular />}
            noBorder
            appearance="transparent"
            onClick={(e) => {
              setManageDependentsOpen(true);
            }}
          >
            {t("hbh.manageDependents.label")}
            {employeeDependentsNumber
              ? `(${employeeDependentsNumber} ${t("hbh.items.label")})`
              : ` (${t("hbh.noData.label")}) `}
          </ActionButton>
        </Grid>
      </Container>

      {manageDependentsOpen && employeeDetails && (
        <EmployeeDependentsDialog
          open={manageDependentsOpen}
          setOpen={onManageDependentsOpen}
          employeeData={employeeDetails}
        />
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;
