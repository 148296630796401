import { BenefitSkeleton } from "components/Skeletons/BenefitSkeleton";
import { useGetMsalUserEmail } from "hooks/useGetMsalUserEmail";
import { useAtomValue } from "jotai";
import { devices } from "library/constants";
import {
  EBenefitContentTab,
  ICardBenefitPackage,
  ICompanyPackageView,
} from "library/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGetCompanyBenefitPackageViews } from "state/useGetCompanyBenefitPackageViews";
import { useGetEmployeeEnrollments } from "state/useGetEnrollments";
import { selectedClientAtom } from "store";
import { benefitContentTabAtom } from "store/UIBenefitsPage";
import styled from "styled-components";
import { ContentSelector } from "./ContentSelector";
import { EligiblePackages } from "./EligiblePackages";
import { EnrolledPackages } from "./EnrolledPackages";

export function BenefitsPageContent() {
  const selectedClient = useAtomValue(selectedClientAtom);
  const selectedBenefitContentTab = useAtomValue(benefitContentTabAtom);
  const userUniqueEmail = useGetMsalUserEmail();

  const { data: companyBenefitPackages } = useGetCompanyBenefitPackageViews({
    clientInternalNumberGos: Number(selectedClient?.clientInternalNumberGos),
    countryCode: Number(selectedClient?.countryCode),
    enabled: !!selectedClient,
  });

  const { data: employeeEnrollments } = useGetEmployeeEnrollments({
    clientInternalNumberGos: Number(selectedClient?.clientInternalNumberGos),
    countryCode: Number(selectedClient?.countryCode),
    enabled: !!selectedClient,
  });

  const packageData = useMemo(() => {
    const eligiblePackages = [] as ICardBenefitPackage[];
    const enrolledPackages = [] as ICardBenefitPackage[];
    if (!companyBenefitPackages || !employeeEnrollments)
      return {
        eligiblePackages,
        enrolledPackages,
      };

    companyBenefitPackages.forEach((benefit) => {
      const enrollments = employeeEnrollments?.filter(
        (item) => item.benefitPackageTag === benefit?.benefitPackageTag
      );

      enrollments.forEach((enrollment) => {
        const isDependent = enrollment?.isDependent;
        if (enrollment.dateOfEnrollment) {
          enrolledPackages.push({
            ...benefit,
            enrolled: true,
            cancellationPossibility: enrollment?.cancellationPossibility || "",
            employeeEnrollmentTag: enrollment.employeeEnrollmentTag,
            dateOfCancellation: enrollment.dateOfCancellation || "",
            isDependent,
            ...(isDependent
              ? { employeeDependentTag: enrollment?.employeeDependentTag }
              : { employeeLoginEmailAddress: userUniqueEmail }),
          });
        } else {
          eligiblePackages.push({
            ...benefit,
            isDependent,
            employeeEnrollmentTag: enrollment.employeeEnrollmentTag,
            dateOfCancellation: enrollment.dateOfCancellation || "",

            cancellationPossibility: enrollment?.cancellationPossibility || "",
            ...(isDependent
              ? { employeeDependentTag: enrollment?.employeeDependentTag }
              : { employeeLoginEmailAddress: userUniqueEmail }),
          });
        }
      });
    });

    return {
      eligiblePackages,
      enrolledPackages,
    };
  }, [employeeEnrollments, companyBenefitPackages, userUniqueEmail]);

  return (
    <Wrap>
      {!companyBenefitPackages || !employeeEnrollments ? (
        <BenefitSkeleton />
      ) : (
        <>
          <ContentSelector />
          <Wrap>
            {selectedBenefitContentTab === EBenefitContentTab.eligible ? (
              <EligiblePackages data={packageData.eligiblePackages} />
            ) : (
              <EnrolledPackages data={packageData.enrolledPackages} />
            )}
          </Wrap>
        </>
      )}
    </Wrap>
  );
}

const Container = styled.div`
  margin-top: 20px;

  @media only screen and ${devices.md} {
    margin-top: 40px;
  }
`;

const Wrap = styled.div`
  margin: 20px 0;

  @media only screen and ${devices.md} {
    margin: 40px 0;
  }
`;
