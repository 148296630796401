import {
  Body1,
  Card,
  CardHeader,
  CardPreview,
  Tag,
  TagGroup,
  Text,
} from "@fluentui/react-components";
import {
  CalendarCancelRegular,
  CalendarClockRegular,
  DismissFilled,
  Gift24Regular,
  GiftOpen24Regular,
  WindowBulletListAddRegular,
} from "@fluentui/react-icons";

import { ButtonWithTooltip } from "components/ButtonWithTooltip/ButtonWithTooltip";
import { StyledTooltip } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
// import { LOCATION_STATES, ROUTES } from "library/constants";
import { isEqual } from "date-fns";
import { ICardBenefitPackage } from "library/types";
import { formatDateString, genUUID } from "library/utils";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PlaceholderImg from "../../assets/download2.png";
import { CancelEnrolledPackage } from "./CancelEnrolledPackage";
import { EnrollPackageDialog } from "./EnrollPackageDialog";

type Props = {
  benefitPackage?: ICardBenefitPackage;

  previewMode?: boolean;
  selected?: boolean;
  isDependent?: boolean;
  eligible?: boolean;
};

export const BenefitCard = ({
  benefitPackage,
  previewMode,
  selected,
  isDependent,
  eligible,
}: Props) => {
  const { t } = useTranslation();

  const [enrollOpen, setEnrollOpen] = useState(false);
  const [cancelEnrollOpen, setCancelEnrollOpen] = useState(false);

  const setEnrollOpenState = useCallback((state) => {
    setEnrollOpen(state);
  }, []);

  const setCancelEnrollOpenState = useCallback((state) => {
    setCancelEnrollOpen(state);
  }, []);

  const theme = useAppTheme();

  const cancelEnrollmentDisabled = (() => {
    const formattedCancelPossibility =
      formatDateString(benefitPackage?.cancellationPossibility) || "";
    const formattedValidTo = formatDateString(benefitPackage?.validTo) || "";

    return isEqual(formattedCancelPossibility, formattedValidTo);
  })();

  const addNewLabel = t("hbh.enrollPackage.label");
  const deleteLabel = t("hbh.cancelPackage.label");
  const disabledCancelLabel = t("hbh.disabledCancelPackage.label");

  const tagGroup = [
    ...(eligible
      ? [
          {
            label: "",
            value: "",
            icon: (
              <ButtonWithTooltip
                tooltipContent={`${t(
                  "hbh.eligibleFrom.label"
                )}: ${formatDateString(benefitPackage?.validFrom)} - ${t(
                  "hbh.eligibleTo.label"
                )}: ${formatDateString(benefitPackage?.validTo)}`}
                icon={<WindowBulletListAddRegular />}
              >
                {`${t("hbh.eligible.label")}: ${formatDateString(
                  benefitPackage?.validFrom
                )} - ${formatDateString(benefitPackage?.validTo)}`}
              </ButtonWithTooltip>
            ),
          },
        ]
      : []),
    {
      label: "",
      value: "",
      icon: (
        <ButtonWithTooltip
          tooltipContent={`${t("hbh.validFrom.label")}: ${formatDateString(
            benefitPackage?.validFrom
          )} - ${t("hbh.validTo.label")}: ${formatDateString(
            benefitPackage?.validTo
          )}`}
          icon={<CalendarClockRegular />}
        >
          {`${t("hbh.valid.label")}: ${formatDateString(
            benefitPackage?.validFrom
          )} - ${formatDateString(benefitPackage?.validTo)}`}
        </ButtonWithTooltip>
      ),
    },
    ...(benefitPackage?.cancellationPossibility &&
    !benefitPackage?.dateOfCancellation
      ? [
          {
            label: "",
            value: "",
            icon: (
              <ButtonWithTooltip
                tooltipContent={`${t(
                  "hbh.cancellationPossibility.label"
                )}: ${formatDateString(
                  benefitPackage?.cancellationPossibility
                )}`}
                icon={<CalendarCancelRegular />}
              >
                {`${t("hbh.cancellationPossibility.label")}: ${formatDateString(
                  benefitPackage?.cancellationPossibility
                )}`}
              </ButtonWithTooltip>
            ),
          },
        ]
      : []),
    ...(benefitPackage?.dateOfCancellation
      ? [
          {
            label: "",
            value: "",
            icon: (
              <ButtonWithTooltip
                style={{
                  color: theme.palette.redDark,
                }}
                tooltipContent={`${t(
                  "hbh.packageIsCanceled.label"
                )}: ${formatDateString(benefitPackage?.dateOfCancellation)}`}
                icon={<CalendarCancelRegular />}
              >
                {`${t("hbh.packageIsCanceled.label")}: ${formatDateString(
                  benefitPackage?.dateOfCancellation
                )}`}
              </ButtonWithTooltip>
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      <StyledCard
        $isSelected={selected}
        $canceled={!!benefitPackage?.dateOfCancellation}
      >
        <CardPreview
          style={{
            height: "250px",
            position: "relative",
            background: theme.palette.white,
          }}
        >
          {eligible ? (
            <ActionWrap $isAdd={!!eligible}>
              <ButtonWithTooltip
                tooltipContent={addNewLabel}
                handleClick={() => {
                  setEnrollOpenState(true);
                }}
                icon={<GiftOpen24Regular />}
              />
            </ActionWrap>
          ) : null}
          {!eligible && !benefitPackage?.dateOfCancellation ? (
            <ActionWrap $isAdd={!!eligible}>
              <ButtonWithTooltip
                disabled={cancelEnrollmentDisabled}
                tooltipContent={
                  cancelEnrollmentDisabled ? disabledCancelLabel : deleteLabel
                }
                handleClick={() => {
                  setCancelEnrollOpenState(true);
                }}
                icon={<DismissFilled />}
              />
            </ActionWrap>
          ) : null}
          <img
            src={benefitPackage?.backgroundPicturePath || PlaceholderImg}
            alt={benefitPackage?.name}
            loading="lazy"
            style={{
              width: "100%",
              cursor: "pointer",
              objectFit: "scale-down",
              // objectFit: "cover",
              // objectFit: "fill",
              // height: "250px",
            }}
          />
        </CardPreview>

        <CardHeader
          style={{
            padding: "5px 12px ",
          }}
          image={
            <Gift24Regular
              style={{
                color: theme.palette.yellowDark,
              }}
            />
          }
          header={
            <Body1>
              <StyledTooltip
                content={benefitPackage?.name || ""}
                relationship="description"
              >
                <Text
                  block
                  truncate
                  weight="bold"
                  style={{
                    maxWidth: "250px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {benefitPackage?.name}
                </Text>
              </StyledTooltip>
            </Body1>
          }
        />

        <StyledTooltip
          relationship="label"
          content={{
            children: benefitPackage?.description,
          }}
        >
          <Desc>{benefitPackage?.description}</Desc>
        </StyledTooltip>
        {tagGroup?.length ? (
          <TagGroup
            aria-label="Simple tag group with Tag"
            role="list"
            style={{
              display: "flex",
              gap: "7px",
              height: "auto",
              flexWrap: "wrap",
              padding: "5px",
            }}
          >
            {tagGroup.map((tag) => {
              return (
                <StyledTag
                  appearance="filled"
                  role="listitem"
                  key={genUUID()}
                  media={tag.icon}
                  shape="circular"
                >
                  {tag.label && <Text weight="semibold">{tag.label}:</Text>}
                  {tag.value && <Text>{tag.value}</Text>}
                </StyledTag>
              );
            })}
          </TagGroup>
        ) : null}
        {benefitPackage?.dateOfCancellation && <CanceledWrap />}
      </StyledCard>
      {enrollOpen && benefitPackage && (
        <EnrollPackageDialog
          open={enrollOpen}
          setOpen={setEnrollOpenState}
          benefitPackage={benefitPackage}
        />
      )}

      {cancelEnrollOpen && benefitPackage && (
        <CancelEnrolledPackage
          open={cancelEnrollOpen}
          setOpen={setCancelEnrollOpenState}
          benefitPackage={benefitPackage}
        />
      )}
    </>
  );
};

const StyledCard = styled(Card)<{ $isSelected?: boolean; $canceled: boolean }>`
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
  position: relative;
  padding: 0 !important;
  gap: 0;

  // opacity: ${({ $isSelected }) => ($isSelected ? "1" : "0.7")};

  box-shadow: ${({ $isSelected, $canceled, theme }) =>
    $canceled
      ? `0 2px 4px ${theme.palette.redDark}`
      : $isSelected
      ? `0 4px 8px ${theme.palette.themeLight}`
      : `0 2px 4px rgba(0, 0, 0, 0.1)`};
`;

const Desc = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0 12px 5px;
  height: 40px;
`;

const ActionWrap = styled.div<{ $isAdd?: boolean }>`
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 35px !important;
  height: 35px !important;
  background-color: ${({ theme, $isAdd }) =>
    $isAdd ? theme.palette.themeLighterAlt : theme.palette.themeLighterAlt};
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 50%;
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 10px;
  z-index: 10;
`;

const StyledTag = styled(Tag)`
  width: auto;
  height: auto;
  background-color: ${({ theme }) => theme.palette.neutralLighterAlt};

  .fui-Tag {
    padding: 0;
  }

  button {
    min-width: auto;
    padding: 0px;
    margin: 0px;
  }
  .fui-Tag__media {
    padding-right: 0px;
    display: flex;
    justify-content: center;
  }
`;

const CanceledWrap = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  opacity: 0.1;
  background-image: linear-gradient(
    to bottom right,
    ${({ theme }) => theme.palette.redDark},
    ${({ theme }) => theme.palette.white}
  );
`;
