import { ButtonProps, Tooltip } from "@fluentui/react-components";
import { StyledButton } from "components/styled";

type Props = {
  tooltipContent?: string;
  handleClick?: () => void;
  icon?: JSX.Element;
} & ButtonProps;

export function ButtonWithTooltip({
  tooltipContent = "",
  handleClick,
  icon,
  ...rest
}: Props) {
  return (
    <Tooltip content={tooltipContent} relationship="description" withArrow>
      <StyledButton
        onClick={handleClick}
        icon={icon}
        appearance="transparent"
        {...rest}
      />
    </Tooltip>
  );
}
