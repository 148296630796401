import { MUTATION_KEYS } from "library/shared";
import { IDependent } from "library/types";
import { handleAxiosError } from "library/utils";
import { useMutation } from "react-query";
import api from "service/api";
import { useInvalidateDependentData } from "./useGetDependentData";
import { useInvalidateEmployeeData } from "./useGetEmployeeData";

type Params = {
  countryCode: number;
  clientInternalNumberGOS: number;
  employeeDependentTag: number;
};

const deleteDependent = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeDependentTag,
}: Params) => {
  const response = await api.delete(
    `/employee/dependent/${countryCode}/${clientInternalNumberGOS}/${employeeDependentTag}`
  );
  return response.data as IDependent;
};

export function useDeleteDependent(refetch = true) {
  const invalidateEmployeeData = useInvalidateEmployeeData();
  const invalidateDependentData = useInvalidateDependentData();

  return useMutation(deleteDependent, {
    mutationKey: MUTATION_KEYS.deleteDependent,
    onSuccess: () => {
      if (refetch) {
        invalidateEmployeeData();
        invalidateDependentData();
      }
    },
    onError: (error) => {
      // handle error
      handleAxiosError(error);
    },
  });
}
