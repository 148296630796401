import { CardListSkeleton } from "./CardListSkeleton";
import { TableSkeleton } from "./TableSkeleton";

type Props = {
  isListView?: boolean;
  isCardView?: boolean;
};

export function BenefitSkeleton({ isListView, isCardView }: Props) {
  if (isListView) {
    return <TableSkeleton />;
  }
  if (isCardView) {
    return <CardListSkeleton />;
  }

  return <CardListSkeleton />;
}
