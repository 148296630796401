import React, { useCallback, useEffect, useState } from "react";
// -- Fabric UI

import {
  Badge,
  Button,
  Persona,
  Select,
  makeStyles,
} from "@fluentui/react-components";
import {
  AlertRegular,
  GridDots28Filled,
  Settings24Regular,
} from "@fluentui/react-icons";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import AppLogo from "../../assets/H&B_logo.png";
import { GrecoPanel } from "./GrecoPanel/GrecoPanel";
import { IGrecoPanelStrings } from "./GrecoPanel/IGrecoPanelStrings";
import { PanelType } from "./GrecoPanel/PanelType";
import { IGrecoHeaderStrings } from "./IGrecoHeaderStrings";
import { NavigationPanel } from "./NavigationPanel/NavigationPanel";
import { useAppTheme } from "../../hooks/useAppTheme";
import { INotification, SelectItem } from "../../library/types";
import { Flex, StyledTooltip } from "../styled";
import { devices, sizes } from "../../library/constants";
import { SelectClient } from "components/SelectClient/SelectClient";
import { UserSettings } from "./GrecoPanel/UserSettings/UserSettings";

interface IProps {
  headerConfig?: any;
  user?: any;
  panelStatus?: (status: boolean) => void;
  logo?: any;
  darkMode?: any;
  headerStrings?: IGrecoHeaderStrings;
  notifications?: INotification[];
  navigation?: JSX.Element;
  clearNotifications?: () => void;
  languageOptions?: SelectItem[];
  defaultLanguage?: string;
  onChangeLanguage?: (
    event: React.FormEvent<HTMLDivElement>,
    option?: SelectItem,
    index?: number
  ) => void;
  isMobile?: boolean;
}

export const iconStyle = makeStyles({
  icon: {
    height: "50px",
    width: "50px",
    color: "white",
    fontSize: "26px",
  },
});

type statePanelType = PanelType | null;

const GrecoHeader = (props: IProps) => {
  const { t } = useTranslation();
  const theme = useAppTheme();
  const [panelType, setPanelType] = useState<statePanelType>(null);
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const [panelStrings, setPanelStrings] = useState<
    IGrecoPanelStrings | undefined
  >();
  const [headerConfig, setHeaderConfig] = useState({
    application_name: "GrECo Header",
    notifications_visible: true,
    help_visible: true,
    account: true,
    search_autofocus: false,
    logo_visible: true,
    title_visible: true,
    settings_visible: true,
    defaultLanguage: "en-GB",
    navigationPanelOpen: false,
  });

  const onNavigationStateChange = useCallback((value) => {
    setHeaderConfig((prev) => ({
      ...prev,
      navigationPanelOpen: value,
    }));
  }, []);

  useEffect(() => {
    if (!props.headerConfig) return;
    setHeaderConfig((prev) => ({ ...prev, ...props.headerConfig }));
  }, [props.headerConfig]);

  useEffect(() => {
    if (!props.headerStrings || !panelType) return;

    if (panelType !== null) {
      const panelStr = updatePanelStrings(
        panelType,
        props.headerStrings
      ) as IGrecoPanelStrings;
      setPanelStrings(panelStr);
    }
  }, [panelType, props.headerStrings]);

  const updatePanelStrings = (
    panelType: PanelType,
    headerStrings
  ): IGrecoPanelStrings | undefined => {
    switch (panelType) {
      case PanelType.Notifications:
        return headerStrings.notificationsPanelStrings;
      case PanelType.Settings:
        return headerStrings.settingsPanelStrings;
      // case PanelType.UserSettings:
      //   return headerStrings.userSettingsPanelStrings;
      default:
        return undefined;
    }
  };

  const togglePanel = (type) =>
    type === panelType ? closePanel() : openPanel(type);

  const openPanel = (type) => {
    if (props.panelStatus) props.panelStatus(true);
    setPanelIsOpen(true);
    setPanelType(type);
  };

  const closePanel = () => {
    if (props.panelStatus) props.panelStatus(false);
    setPanelIsOpen(false);
    setPanelType(null);
  };

  return (
    <Container>
      <GrecoPanel
        onDismiss={closePanel}
        panelIsOpen={panelIsOpen}
        panelType={panelType}
        userData={props.user}
        headerConfig={headerConfig}
        darkMode={props.darkMode}
        panelStrings={panelStrings}
        notifications={props.notifications}
        clearNotifications={props.clearNotifications}
        isMobile={props.isMobile}
        togglePanel={togglePanel as () => void}
        languageOptions={props.languageOptions}
        defaultSelectedLanguage={
          props.defaultLanguage || headerConfig.defaultLanguage
        }
        onChangeLanguage={props.onChangeLanguage}
      />
      {props.navigation && (
        <>
          <StyledTooltip
            content={t("hbh.navigation.label")}
            relationship="description"
          >
            <NavigationButton
              appearance="outline"
              onClick={() => {
                onNavigationStateChange(true);
              }}
              icon={<GridDots28Filled />}
            />
          </StyledTooltip>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            className="greco-header__tick"
            width="24"
            height="24"
            style={{
              fill: "#fff",
              left: "0px",
              position: "absolute",
              top: "0px",
              zIndex: 1,
            }}
          >
            <path d="M 0,0 24,0 0,12 z" />
          </svg>
        </>
      )}
      <NavigationPanel
        navigation={props.navigation}
        onNavigationStateChange={onNavigationStateChange}
        navigationPanelOpen={headerConfig.navigationPanelOpen}
      />

      <ContentWrap>
        <Flex wrap="nowrap">
          {headerConfig.logo_visible ? (
            <img
              className="logo"
              src={props.logo}
              alt={headerConfig.application_name}
              style={{
                height: "38px",
              }}
            />
          ) : null}
          <img
            src={AppLogo}
            style={{
              marginLeft: "10px",
              width: "41px",
              height: "38px",
            }}
            alt="app_logo"
          />
          {headerConfig.logo_visible ? (
            <AppName>{headerConfig.application_name}</AppName>
          ) : null}
          <SelectClient onlyMobile />
        </Flex>
        <ActionIconsWrap>
          <Button
            icon={
              <div>
                <AlertRegular />
                <Badge
                  style={{
                    position: "absolute",
                    top: "5px",
                    left: "20px",
                    display: props.notifications?.length ? "block" : "none",
                  }}
                >
                  {props.notifications?.length}
                </Badge>
              </div>
            }
            onClick={() => togglePanel(PanelType.Notifications)}
            appearance="transparent"
            style={{
              color: theme.palette.white,
            }}
          />
          <Button
            icon={
              <div>
                <Settings24Regular />
              </div>
            }
            onClick={() => togglePanel(PanelType.Settings)}
            appearance="transparent"
            style={{
              color: theme.palette.white,
            }}
          />

          {headerConfig.account && props.user ? (
            <UserSettings userData={props.user} />
          ) : null}
        </ActionIconsWrap>
      </ContentWrap>
    </Container>
  );
};

export default GrecoHeader;

const Container = styled.div`
  background: ${({ theme }) => theme.palette.themePrimary};
  display: flex;
  width: 100%;
  // height: 50px;
  line-height: ${sizes.appHeader}px;
`;

const ContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: ${sizes.appHeader}px;
  padding: 0 10px;
  @media only screen and ${devices.md} {
    padding: 0 10px;
  }
`;
const AppName = styled.div`
  font-size: 16px;
  margin-left: 10px;
  color: ${({ theme }) => theme.palette.white};
  display: none;
  @media only screen and ${devices.md} {
    display: block;
  }
`;

const ActionIconsWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  @media only screen and ${devices.md} {
    gap: 20px;
  }
`;

const NavigationButton = styled(Button)`
  height: 50px;
  width: 50px;
  max-width: 50px;
  max-height: 50px;
  background: transparent;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.palette.white};
  &:hover {
    color: ${({ theme }) => theme.palette.white};
    transition-property: transform;
    transition-duration: 0.2s;
    transform: scale(1.1);
  }
`;
