import { PageContent } from "components/styled";
import React from "react";
import { BenefitsPageHeader } from "./components/BenefitsPageHeader/BenefitsPageHeader";
import { BenefitsPageContent } from "./components/BenefitsPageContent/BenefitsPageContent";

export function BenefitsPage() {
  return (
    <>
      <PageContent>
        <BenefitsPageHeader />
        <BenefitsPageContent />
      </PageContent>
    </>
  );
}
