import { atom } from "jotai";
import {
  EBenefitContentTab,
  IDependent,
  IEmployee,
  SelectItem,
} from "library/types";

export const benefitContentTabAtom = atom<EBenefitContentTab>(
  EBenefitContentTab.enrolled
);

export const selectedPackageUserAtom = atom<SelectItem | null>(null);
