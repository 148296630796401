import { DatePicker } from "@fluentui/react-datepicker-compat";
import {
  ErrorMessageContainer,
  FormFieldContainer,
  StyledErrorMessage,
} from "components/styled";
import * as React from "react";

import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "@fluentui/react-components";
import { ClearIcon, RemoveIcon } from "@fluentui/react-icons-mdl2";
import { useAppTheme } from "hooks/useAppTheme";

type Props = {
  name: string;
  value: Date;
  label: string;
  required?: boolean;
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
  error?: string;
  info?: string;
  setFieldValue: (name: string, value: Date | null) => void;
  allowTextInput?: boolean;
  noErrorSection?: boolean;
  disableInitSetValue?: boolean;
};

const onFormatDate = (date?: Date | undefined): string => {
  return !date
    ? ""
    : ((date?.getDate() + "").length === 1 ? "0" : "") +
        date?.getDate() +
        "." +
        ((date?.getMonth() + 1 + "").length === 1 ? "0" : "") +
        (date?.getMonth() + 1) +
        "." +
        date?.getFullYear();
};

const DatePickerField = ({
  name,
  value,
  label,
  maxDate,
  minDate,
  info,
  required = false,
  disabled = false,
  error,
  setFieldValue,
  allowTextInput = true,
  disableInitSetValue,
  noErrorSection,
}: Props) => {
  const theme = useAppTheme();
  const { t } = useTranslation();
  const datePickerRef = React.useRef<any>(null);

  const onParseDateFromString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = value ? new Date(value) : new Date();
      const newValueParts = (newValue || "").trim().split(".");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0] || "", 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1] || "", 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2] || "", 10)
          : previousValue.getFullYear();
      if (year < 100) {
        year +=
          previousValue.getFullYear() - (previousValue.getFullYear() % 100);
      }

      return new Date(year, month, day);
    },
    [value]
  );

  React.useEffect(() => {
    if (value && !disableInitSetValue) {
      setFieldValue(name, value);
    }
  }, []);

  return (
    <FormFieldContainer
      style={{
        position: "relative",
      }}
    >
      {label ? (
        <FormFieldLabel
          disabled={disabled}
          label={label}
          required={required}
          info={info}
        />
      ) : null}

      <DatePicker
        disabled={disabled}
        appearance="underline"
        ref={datePickerRef}
        allowTextInput={allowTextInput}
        value={value ? new Date(value) : null}
        onSelectDate={(date?: Date | null) => {
          setFieldValue(name, date || null);
        }}
        onFocus={(e) => {
          e.preventDefault();
        }}
        openOnClick={false}
        maxDate={maxDate}
        minDate={minDate}
        formatDate={onFormatDate}
        parseDateFromString={onParseDateFromString}
        placeholder={t("greco.form.selectPlaceholder")}
        // onValidationResult={(data) => console.log(data)}
      />
      {noErrorSection ? null : (
        <ErrorMessageContainer>
          <StyledErrorMessage>{error}</StyledErrorMessage>
        </ErrorMessageContainer>
      )}
      {!disabled && (
        <ClearButton
          style={{
            display: value ? "block" : "none",
          }}
          onClick={() => {
            setFieldValue(name, null);
          }}
          appearance="transparent"
          icon={
            <ClearIcon
              style={{
                color: theme.palette.redDark,
                width: "10px",
                height: "10px",
              }}
            />
          }
        />
      )}
    </FormFieldContainer>
  );
};

export default DatePickerField;

const ClearButton = styled(Button)`
  position: absolute;
  right: 27px;
  top: 32px;
  padding: 0;
  height: 20px;
`;
